import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// CSS
import styles from "./alert.module.scss";

// Hooks
import { useAlert } from "./hooks/useAlert";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/objectUtils";

export const Alert = ({ children, className, config, onClose }) => {
    const { shouldShowClose } = config;

    /**
     * Custom Hooks
     */

    const { handleClose, isVisible } = useAlert({ onClose });

    /**
     * End Custom Hooks
     */

    return (
        <div
            className={cx(styles.alert, className, {
                [styles["alert--fadeOut"]]: !isVisible,
            })}
        >
            {shouldShowClose && (
                <div
                    className={styles.close}
                    onClick={handleClose}
                    onKeyDown={(e) => {
                        handleKeyDown(e, handleClose);
                    }}
                    tabIndex="0"
                >
                    <HighlightOffIcon />
                </div>
            )}
            {children}
        </div>
    );
};

Alert.defaultProps = {
    className: "",
    config: {
        shouldShowClose: false,
    },
    onClose: emptyFunction,
};

Alert.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    config: PropTypes.object,
    onClose: PropTypes.func,
};
