// Platforms
import { CurrentUser } from "./sandboxx";

// Utils
import { emptyFunction } from "./objectUtils";
import { convertArrayBufferToBase64 } from "./photoUtils";

function deleteCall(
    url,
    headers,
    body,
    onSuccess = emptyFunction,
    onFailure = emptyFunction,
    onError = emptyFunction
) {
    return fetch(url, {
        body,
        headers,
        method: "DELETE",
    })
        .then(function (response) {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return onSuccess(json);
                    } else {
                        return onFailure(json);
                    }
                });
            }
        })
        .catch(function (error) {
            onError(error);
        });
}

function get(
    url,
    headers,
    onSuccess = emptyFunction,
    onFailure = emptyFunction,
    onError = emptyFunction
) {
    return fetch(url, { headers })
        .then(function (response) {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
            } else if (response.status === 204) {
                if (response.ok) {
                    return onSuccess(null, response);
                } else {
                    return onFailure(null, response);
                }
            } else {
                return response.json().then(function (json) {
                    if (response.ok) {
                        return onSuccess(json, response);
                    } else {
                        return onFailure(json, response);
                    }
                });
            }
        })
        .catch(function (error) {
            onError(error);
        });
}

function getV2(url, headers) {
    return fetch(url, { headers })
        .then((response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return Promise.resolve({ json, response });
                    } else {
                        return Promise.reject({ json, response });
                    }
                });
            }
        })
        .catch(function (error) {
            return Promise.reject({ error });
        });
}

function getImage(
    url,
    headers,
    onSuccess = emptyFunction,
    onFailure = emptyFunction,
    onError = emptyFunction,
    { fileType }
) {
    return fetch(url, { headers })
        .then((response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
            } else {
                return response.arrayBuffer().then((buffer) => {
                    const base64Flag = `data:image/${fileType};base64,`;
                    const imageStr = convertArrayBufferToBase64(buffer);
                    const base64ImageStr = base64Flag + imageStr;
                    return onSuccess(base64ImageStr);
                });
            }
        })
        .catch(function (error) {
            return onError(error);
        });
}

function getImageV2(url, headers, { fileType }) {
    return fetch(url, { headers })
        .then((response) => {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
                return Promise.reject("Redirected to Sign In");
            } else {
                return response.arrayBuffer().then((buffer) => {
                    const base64Flag = `data:image/${fileType};base64,`;
                    const imageStr = convertArrayBufferToBase64(buffer);
                    return base64Flag + imageStr;
                });
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function post(
    url,
    headers,
    body,
    onSuccess = emptyFunction,
    onFailure = emptyFunction,
    onError = emptyFunction
) {
    return fetch(url, {
        body,
        headers,
        method: "POST",
    })
        .then(function (response) {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return onSuccess({
                            ...json,
                            responseStatusCode: response.status,
                        });
                    } else {
                        return onFailure(json);
                    }
                });
            }
        })
        .catch(function (error) {
            onError(error);
        });
}

function put(
    url,
    headers,
    body,
    onSuccess = emptyFunction,
    onFailure = emptyFunction,
    onError = emptyFunction
) {
    return fetch(url, {
        body,
        headers,
        method: "PUT",
    })
        .then(function (response) {
            if (shouldRedirectToSignIn(response)) {
                handleRedirectToSignIn();
            } else {
                return response.json().then((json) => {
                    if (response.ok) {
                        return onSuccess(json);
                    } else {
                        return onFailure(json);
                    }
                });
            }
        })
        .catch(function (error) {
            onError(error);
        });
}

function handleRedirectToSignIn() {
    CurrentUser.clearUser();
    window.location.href = "/signin";
}

function shouldRedirectToSignIn(response) {
    return response.status === 401;
}

export { deleteCall, get, getImage, getImageV2, getV2, post, put };
