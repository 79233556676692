import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

// CSS
import buttonsStyles from "components/Buttons/buttons.module.scss";
import styles from "./button-rounded.module.scss";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const ButtonRounded = (props) => {
    const {
        classes,
        cypressTestId,
        id,
        isDisabled,
        isFilled,
        isLoading,
        onClick,
        text,
    } = props;

    if (isLoading) {
        return (
            <button className={styles.buttonRounded}>
                <Skeleton variant="rect" width="20%" height="20px" />
            </button>
        );
    } else {
        return (
            <button
                className={cx(
                    buttonsStyles.button,
                    styles.buttonRounded,
                    classes,
                    {
                        [styles["buttonRounded--filled"]]: isFilled,
                    }
                )}
                data-cy={cypressTestId}
                disabled={isDisabled}
                id={id}
                onClick={onClick}
            >
                <span>{text}</span>
            </button>
        );
    }
};

ButtonRounded.defaultProps = {
    classes: "",
    cypressTestId: "",
    id: "",
    isDisabled: false,
    isFilled: false,
    isLoading: false,
    onClick: emptyFunction,
    text: "",
};

ButtonRounded.propTypes = {
    classes: PropTypes.string,
    cypressTestId: PropTypes.string,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    isFilled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string,
};
