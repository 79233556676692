import cx from "classnames";
import PropTypes from "prop-types";

// CSS
import styles from "./button-tertiary.module.scss";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const ButtonTertiary = (props) => {
    const { classNames, children, cyId, id, isDisabled, onClick, text, type } =
        props;

    return (
        <button
            className={cx(
                styles.button,
                styles["button-tertiary"],
                classNames,
                {
                    disabled: isDisabled,
                }
            )}
            data-cy={cyId}
            disabled={isDisabled}
            id={id}
            onClick={onClick}
            type={type}
        >
            {children ? children : <span>{text}</span>}
        </button>
    );
};

ButtonTertiary.defaultProps = {
    classNames: "",
    children: null,
    cyId: "",
    id: "",
    isDisabled: false,
    onClick: emptyFunction,
    text: "",
    type: "",
};

ButtonTertiary.propTypes = {
    classNames: PropTypes.string,
    children: PropTypes.element,
    cyId: PropTypes.string,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string,
    type: PropTypes.string,
};
