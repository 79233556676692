// Interfaces
import { InboxManager } from "./InboxManager";

// Models
import { InboxMessage } from "../../models/inbox/InboxMessage";

// Platforms
import { Iterable } from "platforms/iterable/iterable";

// Utils
import { emptyFunction } from "utils/objectUtils";

class IterableInboxManager extends InboxManager {
    deleteMessage(
        messageToDelete,
        {
            onFailure = emptyFunction,
            onStart = emptyFunction,
            onSuccess = emptyFunction,
        } = {}
    ) {
        const messageToDeleteIndex = this.messages.findIndex(
            (message) => message.id === messageToDelete.id
        );
        this.messages.splice(messageToDeleteIndex, 1);
        onStart();
        Iterable.deleteInboxMessage(messageToDelete, { onFailure, onSuccess });
    }

    fetchMessages({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        return Iterable.getInboxMessages({
            onFailure: () => {
                onFailure();
            },
            onSuccess: ({ data }) => {
                this.messages = this.generateInboxMessages(data.inAppMessages);
                this.generateCounts();
                this.hasMadeInitialMessagesFetch = true;
                onSuccess();
            },
        });
    }

    generateCounts() {
        this.totalMessageCount = this.messages.length;
        this.unreadMessageCount = this.messages.reduce(
            (count, message) => (message.isRead ? count : count + 1),
            0
        );
        this.hasMessages = this.totalMessageCount > 0;
    }

    generateInboxMessages(messagesData) {
        return messagesData.map((message) => {
            return new InboxMessage({
                html: message.content.html,
                id: message.messageId,
                isRead: message.read,
                subtitle: message.inboxMetadata.subtitle,
                title: message.inboxMetadata.title,
            });
        });
    }

    markMessageAsRead(message, { onSuccess = emptyFunction } = {}) {
        // Mark as read via Iterable API
        Iterable.markInboxMessageAsRead(message);

        // Mark as ready locally
        message.markAsRead();
        this.generateCounts();

        // Callback
        onSuccess();
    }
}

const IterableInboxManagerInstance = new IterableInboxManager();

export default IterableInboxManagerInstance;
