import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { UserListItem } from "./UserListItem";

// Utils
import { hasValidAddress } from "../User/UserList/utils";
import { emptyFunction } from "utils/objectUtils";

export const UserList = (props) => {
    const {
        avatarClassNames,
        classNames,
        isClickable,
        itemsClassNames,
        isLoading,
        config,
        shouldDisplayShipDate,
        headerText,
        selectedUserListItem,
        userList,
    } = props;

    useEffect(() => {
        if (selectedUserListItem.abContactId) {
            const tgtElement = document.getElementById(
                `user-list-item-user-${selectedUserListItem.abContactId}`
            );

            tgtElement?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function renderHeader() {
        return (
            headerText && (
                <div className="panel__header">
                    <span className="panel__header__text">{headerText}</span>
                </div>
            )
        );
    }

    function renderUserListItems() {
        return (
            userList &&
            userList.length && (
                <div className={cx("user-list__items", itemsClassNames)}>
                    {userList &&
                        userList
                            .filter((user) =>
                                config.hideItemsWithoutAddress &&
                                !hasValidAddress(user)
                                    ? false
                                    : true
                            )
                            .map((user, index) => {
                                return (
                                    <UserListItem
                                        {...props}
                                        avatarClassNames={avatarClassNames}
                                        shouldDisplayShipDate={
                                            shouldDisplayShipDate
                                        }
                                        isClickable={isClickable}
                                        config={config}
                                        key={user.id}
                                        selectedUserListItem={
                                            selectedUserListItem
                                        }
                                        user={user}
                                    />
                                );
                            })}
                </div>
            )
        );
    }

    return (
        <div
            className={cx(classNames, "user-list panel", {
                "user-list--loading": isLoading,
            })}
        >
            {renderHeader()}
            {renderUserListItems()}
        </div>
    );
};

UserList.defaultProps = {
    classNames: "",
    config: {
        hideItemsWithoutAddress: false,
    },
    shouldDisplayShipDate: false,
    handleUserSelect: emptyFunction,
    headerText: "",
    icon: {},
    isComposingLetter: false,
    userList: [],
    selectedUserListItem: {},
};
