import { useEffect, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { generateSetShippingOptionPayload } from "../utils/apiUtils";
import { emptyFunction } from "utils/objectUtils";

export const useCartShippingOptions = ({
    cart,
    setCart,
    setIsUpdatingCart,
}) => {
    /**
     * useState
     */

    const [shippingOptions, setShippingOptions] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        /**
         * Fetch shipping options whenever the cart is updated
         */
        getShippingOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart?.updated_at]);

    /**
     * End Hooks
     */

    function getShippingOptions({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        setIsUpdatingCart(() => true);
        SandboxxRestAPI.getShippingOptions(
            { cart },
            (res) => onGetShippingOptionsSuccess(res, onSuccess),
            (err) => onGetShippingOptionsFailure(err, onFailure),
            (err) => onGetShippingOptionsFailure(err, onFailure)
        );
    }

    function resetCartShippingOptionsContext() {
        setShippingOptions(null);
    }

    function submitShippingOption({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        shippingOption,
    } = {}) {
        setIsUpdatingCart(() => true);
        const payload = generateSetShippingOptionPayload({
            cart,
            shippingOption,
        });
        SandboxxRestAPI.submitShippingOption(
            { cart, ...payload },
            (res) =>
                onSubmitShippingOptionSuccess(res, onSuccess, shippingOption),
            (err) => onSubmitShippingOptionFailure(err, onFailure),
            (err) => onSubmitShippingOptionFailure(err, onFailure)
        );
    }

    function onGetShippingOptionsFailure(err) {
        setIsUpdatingCart(() => false);
    }

    function onGetShippingOptionsSuccess({ shipping_options }) {
        setShippingOptions(() => shipping_options);
        setIsUpdatingCart(() => false);
    }

    function onSubmitShippingOptionFailure(err, onFailure) {
        setIsUpdatingCart(() => false);
        onFailure(err);
    }

    function onSubmitShippingOptionSuccess(res, onSuccess, shippingOption) {
        setCart(res.cart);
        setIsUpdatingCart(() => false);
        onSuccess(res, shippingOption);
    }

    return {
        resetCartShippingOptionsContext,
        submitShippingOption,
        shippingOptions,
    };
};
