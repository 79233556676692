import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonPrimary, ButtonInvisible } from "../../../Buttons/Buttons";
import { DropdownCountry } from "components/Dropdowns/DropdownCountry/DropdownCountry";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { Select } from "components/Inputs/Select/Select";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";
import { ToggleSwitch } from "components/ToggleSwitch/ToggleSwitch";

// CSS
import styles from "./person-modal-form.module.scss";

// Hooks
import { usePersonModalForm } from "./hooks/usePersonModalForm";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { titleCase } from "utils/textUtils";
import { generateFullName } from "utils/userUtils";

export const PersonModalForm = ({
    config,
    headerTextOptions,
    loading,
    onChange,
    onFormGoBack,
    onSubmit,
    personStaging,
    setCurrentSection,
    setHeaderText,
    shouldReverseFullName,
    titles,
    toggleShouldReverseFullName,
}) => {
    const { address, firstName, lastName } = personStaging;
    const hasNameAndAddress =
        firstName &&
        lastName &&
        address.line1 &&
        address.city &&
        address.state &&
        address.zipcode;
    const canSubmit = !loading.form && hasNameAndAddress;

    /**
     * Custom Hooks
     **/

    const { handleGoToSelectBase, setStateSelect, setStateText } =
        usePersonModalForm({
            config,
            headerTextOptions,
            onChange,
            personStaging,
            setCurrentSection,
            setHeaderText,
        });

    /**
     * End Hooks
     **/

    function renderAddressFormatterBanner() {
        return config.shouldHideFormAddressFormatterBanner ? null : (
            <Banner
                classes={styles.banner}
                config={{ shouldShowChevron: true }}
                onClick={handleGoToSelectBase}
                tabFlow
            >
                <Text isBold>Need Help?</Text>
                <Text>
                    If your recipient's address has changed significantly, click
                    here to use our address formatter assistant.
                </Text>
            </Banner>
        );
    }

    function renderCountrySelect() {
        return config.shouldShowFormCountryField ? (
            <DropdownCountry
                className={cx(styles.input, styles["input--country"])}
                cypressTestId="person-modal-form-country"
                name="address.country"
                onChange={onChange}
                value={address.country}
            />
        ) : null;
    }

    function renderPreview() {
        return config.shouldHideFormAddressPreview ? null : (
            <div
                className={cx(styles.preview, {
                    [styles["preview--hidden"]]: !firstName || !lastName,
                })}
                data-cy="person-modal-form-preview"
            >
                <Text>Letters will be addressed to:</Text>
                <Text isBold>
                    {generateFullName(personStaging, shouldReverseFullName)}
                </Text>
            </div>
        );
    }

    function renderReverseFullNameToggleSwitch() {
        return config.shouldHideFormNameToggle ? null : (
            <ToggleSwitch
                checked={shouldReverseFullName}
                classes={cx(styles.toggle, {
                    [styles["toggle--hidden"]]: !firstName || !lastName,
                })}
                cypressTestId="person-modal-form-toggle"
                labelSwitchText="Reverse first and last name?"
                name="toggleReverseName"
                onChange={toggleShouldReverseFullName}
            />
        );
    }

    function renderStateField() {
        const onChangeState = (e) => {
            // Store the selected state in the useState value that matches the input field type
            if (config.shouldEnableStateFieldToggle) {
                !address.country || address.country === "US"
                    ? setStateSelect(e.target.value)
                    : setStateText(e.target.value);
            }
            // Update userStaging with the selected state
            onChange(e);
        };
        if (
            !config.shouldEnableStateFieldToggle ||
            (config.shouldEnableStateFieldToggle &&
                (!address.country || address.country === "US"))
        ) {
            return (
                <DropdownState
                    className={cx(styles.input, styles["input--state"])}
                    cypressTestId="person-modal-form-state"
                    name="address.state"
                    onChange={onChangeState}
                    required
                    value={address.state}
                />
            );
        } else {
            return (
                <TextField
                    className={cx(styles.input, styles["input--state"])}
                    cypressTestId="person-modal-form-state"
                    id="state"
                    label="State"
                    name="address.state"
                    onChange={onChangeState}
                    required
                    value={address.state}
                />
            );
        }
    }

    function renderTitleSelect() {
        return config.shouldHideFormTitleField ? null : (
            <Select
                className={cx(styles.input, styles["input--title"])}
                cypressTestId="person-modal-form-title-select"
                id="person-modal-form-title-select"
                label="Rank"
                labelId="person-modal-form-title-select"
                name="title"
                onChange={onChange}
            >
                <option value={null} />
                {titles.map((option) => (
                    <option
                        key={`${option.title} ${option.branchId}`}
                        value={option.title}
                    >
                        {`${option.title} ${titleCase(option.branchId)}`}
                    </option>
                ))}
            </Select>
        );
    }

    return (
        <div className={styles.personModalForm}>
            <form
                className={cx(styles.form, "form")}
                id="person-modal__form"
                onSubmit={canSubmit ? onSubmit : emptyFunction}
            >
                <TextField
                    className={cx(styles.input, styles["input--firstName"])}
                    cypressTestId="person-modal-form-first-name"
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={onChange}
                    required
                    value={firstName}
                />

                <TextField
                    className={cx(styles.input, styles["input--lastName"])}
                    cypressTestId="person-modal-form-last-name"
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={onChange}
                    required
                    value={lastName}
                />
                {renderTitleSelect()}
                {renderPreview()}
                {renderReverseFullNameToggleSwitch()}
                <TextField
                    className={cx(styles.input, styles["input--line1"])}
                    cypressTestId="person-modal-form-line-1"
                    id="line1"
                    label="Address Line 1"
                    name="address.line1"
                    onChange={onChange}
                    required
                    value={address.line1}
                />
                <TextField
                    className={cx(styles.input, styles["input--line2"])}
                    cypressTestId="person-modal-form-line-2"
                    id="line2"
                    label="Address Line 2"
                    name="address.line2"
                    onChange={onChange}
                    value={address.line2}
                />
                <TextField
                    className={cx(styles.input, styles["input--city"])}
                    cypressTestId="person-modal-form-city"
                    id="city"
                    label="City"
                    name="address.city"
                    onChange={onChange}
                    required
                    value={address.city}
                />
                {renderStateField()}
                <TextField
                    className={cx(styles.input, styles["input--zipcode"])}
                    cypressTestId="person-modal-form-zipcode"
                    id="zipcode"
                    label="Zip Code"
                    name="address.zipcode"
                    onChange={onChange}
                    required
                    value={address.zipcode}
                />
                {renderCountrySelect()}
                {renderAddressFormatterBanner()}
                <div className={styles.buttons}>
                    <ButtonPrimary
                        cypressTestId="person-modal-form-submit-button"
                        isDisabled={!hasNameAndAddress}
                        isLoading={loading.form}
                        onClick={onSubmit}
                        type="submit"
                    >
                        <Text>Confirm</Text>
                    </ButtonPrimary>
                    {config.shouldShowFormBackButton && (
                        <ButtonInvisible
                            cypressTestId="person-modal-form-back-button"
                            onClick={onFormGoBack}
                            type="reset"
                        >
                            <Text>Back</Text>
                        </ButtonInvisible>
                    )}
                </div>
            </form>
        </div>
    );
};

PersonModalForm.propTypes = {
    config: PropTypes.shape({
        shouldShowFormBackButton: PropTypes.bool,
    }),
    headerTextOptions: PropTypes.object.isRequired,
    loading: PropTypes.shape({
        form: PropTypes.bool,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    personStaging: PropTypes.object,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
    shouldReverseFullName: PropTypes.bool.isRequired,
    titles: PropTypes.arrayOf(
        PropTypes.shape({
            branchId: PropTypes.string,
            title: PropTypes.string,
        })
    ),
    toggleShouldReverseFullName: PropTypes.func,
};
