import sha256 from "crypto-js/sha256";

// Utils
import { generateDepLocationsUrl } from "./depUtils.js";
import {
    deleteCall,
    get,
    getImage,
    getImageV2,
    getV2,
    post,
    put,
} from "./fetchUtils.js";
import { removeNullUndefinedEmptyFromObject } from "./objectUtils.js";
import { emptyFunction } from "./objectUtils.js";
import { DEV, restBase } from "./urlUtils.js";

function getSandboxxUserKey() {
    return DEV ? "SandboxxDevUser" : "SandboxxUser";
}

export class CurrentUser {
    static clearUser() {
        localStorage.removeItem(getSandboxxUserKey());
        sessionStorage.setItem("urlQueryParams", "{}");
    }

    static getUser() {
        return JSON.parse(localStorage.getItem(getSandboxxUserKey()));
    }

    static isLoggedIn() {
        return (
            localStorage.getItem(getSandboxxUserKey()) !== undefined &&
            localStorage.getItem(getSandboxxUserKey()) !== null
        );
    }

    static isOnboardingRequired() {
        const user = JSON.parse(localStorage.getItem(getSandboxxUserKey()));
        return user.onboarding;
    }

    static isVerified() {
        const user = JSON.parse(localStorage.getItem(getSandboxxUserKey()));
        return user.phoneVerified;
    }

    // TODO: Store address in object
    static storeUser(user, { phoneVerified } = {}, { required } = {}) {
        const { forcePasswordReset, intercomHash, token } =
            CurrentUser.getUser();
        const userToStore = {
            avatar: user.avatarS,
            birthday: user.birthday,
            branchId: user.branchId,
            city: user.address && user.address.city,
            country: user.address && user.address.country,
            email: user.email,
            firstName: user.firstName,
            forcePasswordReset: forcePasswordReset,
            fullName: user.fullName,
            gender: user.gender,
            hasSandboxxPlus: user.hasSandboxxPlus,
            intercomHash: intercomHash,
            lastName: user.lastName,
            line1: user.address && user.address.line1,
            line2: user.address && user.address.line2,
            onboarding: required,
            personas: user.personas || [],
            phoneNumber: user.phoneNumber,
            phoneVerified,
            rank: user.rank,
            role: user.role,
            state: user.address && user.address.state,
            token: token,
            userId: user.id,
            zipcode: user.address && user.address.zipcode,
        };
        localStorage.setItem(getSandboxxUserKey(), JSON.stringify(userToStore));
    }

    // TODO: Store address in object
    static storeUserSignIn(
        user,
        { force_password_reset, intercom_hash, intercomHash, token } = {},
        { phoneVerified } = {},
        { required } = {}
    ) {
        const userToStore = {
            avatar: user.avatarS,
            birthday: user.birthday,
            branchId: user.branchId,
            city: user.address && user.address.city,
            country: user.address && user.address.country,
            email: user.email,
            firstName: user.firstName,
            forcePasswordReset: force_password_reset,
            fullName: user.fullName,
            gender: user.gender,
            hasSandboxxPlus: user.hasSandboxxPlus,
            intercomHash: intercom_hash || intercomHash,
            lastName: user.lastName,
            line1: user.address && user.address.line1,
            line2: user.address && user.address.line2,
            onboarding: required,
            personas: user.personas || [],
            phoneNumber: user.phoneNumber,
            phoneVerified,
            rank: user.rank,
            role: user.role,
            state: user.address && user.address.state,
            token: token,
            userId: user.id,
            zipcode: user.address && user.address.zipcode,
        };
        localStorage.setItem(getSandboxxUserKey(), JSON.stringify(userToStore));
    }

    static toggleOnboarding(bool) {
        const user = JSON.parse(localStorage.getItem(getSandboxxUserKey()));
        const userToStore = { ...user, onboarding: bool };
        localStorage.setItem(getSandboxxUserKey(), JSON.stringify(userToStore));
    }
}

export class SandboxxRestAPI {
    static restAuthHeader() {
        const headers = new Headers();
        const { token, userId } = CurrentUser.getUser();
        const authString = `Basic ${btoa(`${userId}:${token}`)}`;
        headers.append("Authorization", authString);
        headers.append("Content-Type", "application/json");
        return headers;
    }

    /** Amazon **/

    static getAmazonMediaType(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/media/amazon/upload/request`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getImageCloudfront(
        urlFragment,
        callback,
        onFailure,
        onError,
        config = { fileType: "jpeg" }
    ) {
        if (CurrentUser.isLoggedIn() && urlFragment) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/media/i/${urlFragment}`;
            return getImage(url, headers, callback, onFailure, onError, config);
        }
    }

    static getImageCloudfrontV2(urlFragment, config = { fileType: "jpeg" }) {
        if (CurrentUser.isLoggedIn() && urlFragment) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/media/i/${urlFragment}`;
            return getImageV2(url, headers, config);
        }
        return Promise.reject("User is not logged in or invalid URL fragment");
    }

    static putImageCloudfront(
        urlFragment,
        imageBinary,
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn() && urlFragment) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/media/i/${urlFragment}`;
            return put(url, headers, imageBinary, callback, onFailure, onError);
        }
    }

    /** Address Book */

    static createAddressBookContact(contact, callback, onFailure, onError) {
        const body = JSON.stringify(contact);
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/address_book/contact`;
        return post(url, headers, body, callback, onFailure, onError);
    }

    static deleteAddressBookContact(contact, callback, onFailure, onError) {
        const id = contact.abContactId || contact.id;
        const body = JSON.stringify({});
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/address_book/contact?ab_contact_id=${id}`;
        return deleteCall(url, headers, body, callback, onFailure, onError);
    }

    static editAddressBookContact(contact, callback, onFailure, onError) {
        const body = JSON.stringify({
            ...contact,
            abContactId: contact.abContactId || contact.id,
        });
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/address_book/contact`;
        return put(url, headers, body, callback, onFailure, onError);
    }

    static getAddressBookContact(id, callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/address_book/contact?ab_contact_id=${id}`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getAddressBookContacts(callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/address_book/contacts`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getAddressBookRecentContacts(callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/address_book/recent`;
        return get(url, headers, callback, onFailure, onError);
    }

    /** Auth **/

    static checkEmailUniqueness(emailInput, callback, onFailure, onError) {
        const email = emailInput.toLowerCase();
        const url = `${restBase}/auth/email/unique?email=${email}`;
        return get(url, {}, callback, onFailure, onError);
    }

    static checkEmailVerificationCodeNonAuth(
        { email, verificationCode },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                emailAddress: email,
                verificationCode: verificationCode,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/email/verification/v2/verify`;
            post(url, headers, body, callback, onFailure, onError);
        }
    }

    static checkEmailVerificationCode(payload, callback, onFailure, onError) {
        const body = JSON.stringify(payload);
        const url = `${restBase}/auth/email/verify`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static checkForOnboardingSkip(callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/associations/kin/skip_onboard`;
        return get(url, headers, callback, onFailure, onError);
    }

    static checkPhoneVerificationCode(
        { verificationCode },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                verificationCode,
                verifyVersion: "v2",
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/phone/verification/v2/verify`;
            post(url, headers, body, callback, onFailure, onError);
        }
    }

    static generateEmailVerificationCodeNonAuth(
        { email, verificationType },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                via: verificationType,
                emailAddress: email,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/email/verification/v2/generate`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static generateEmailVerificationCode(
        { email, recaptchaToken },
        callback,
        onFailure,
        onError
    ) {
        const body = JSON.stringify({
            emailAddress: email,
            platform: "WEB",
            recaptchaToken,
        });
        const url = `${restBase}/auth/email/generate`;
        return post(url, {}, body, callback, onFailure, onError);
    }

    static generatePhoneVerificationCode(
        { phoneNumber, via },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                countryCode: phoneNumber.countryCode,
                phoneNumber: phoneNumber.baseNumber.replace(/^(\+)|\D/g, "$1"),
                verifyVersion: "v2",
                via,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/phone/verification/v2/generate`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static login(data, callback, onError, onFailure) {
        if (CurrentUser.isLoggedIn()) return;
        const { email, password, musterInviteCode } = data;
        const body = JSON.stringify({
            email,
            password: sha256(password).toString(),
            platform: "WEB",
            musterInviteCode,
        });
        const url = `${restBase}/auth/loginV2`;
        return post(url, {}, body, callback, onFailure, onError);
    }

    static logout(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({});
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/auth/logout`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static resendEmailVerificationCode(
        { email, recaptchaToken },
        callback,
        onFailure,
        onError
    ) {
        const body = JSON.stringify({
            emailAddress: email,
            platform: "WEB",
            recaptchaToken,
        });
        const url = `${restBase}/auth/email/generate`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static resendPhoneVerificationCode(
        { phoneNumber, verificationType },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const { baseNumber, countryCode } = phoneNumber;
            const body = JSON.stringify({
                countryCode,
                phoneNumber: baseNumber.replace(/^(\+)|\D/g, "$1"),
                verifyVersion: "v2",
                via: verificationType,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/phone/verification/v2/resend`;
            post(url, headers, body, callback, onFailure, onError);
        }
    }

    static resendPhoneVerificationCodeNonAuth(
        payload,
        callback,
        onFailure,
        onError
    ) {
        const body = JSON.stringify(payload);
        const url = `${restBase}/auth/phone/generate`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static requestPasswordResetEmail(email, callback, onFailure, onError) {
        const body = { email: email };
        const url = `${restBase}/auth/password/forgot`;
        return post(
            url,
            {},
            JSON.stringify(body),
            callback,
            onFailure,
            onError
        );
    }

    static resetPassword(
        { password, newPassword, confirmNewPassword },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                password: sha256(password).toString(),
                newPassword: sha256(newPassword).toString(),
                confirmPassword: sha256(confirmNewPassword).toString(),
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/auth/password`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static phoneSignUpGenerate(user, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) return;
        const body = JSON.stringify(user);
        const url = `${restBase}/auth/phone/generate`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static phoneSignUpVerify(user, callback, onFailure, onError) {
        const body = JSON.stringify(user);
        const url = `${restBase}/auth/phone/verify`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static phoneSignUpUpdateAttributes(data, callback, onFailure, onError) {
        const body = JSON.stringify(data);
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/auth/update/attributes`;
        post(url, headers, body, callback, onFailure, onError);
    }

    static phoneGeneratePreDelete(data, callback, onFailure, onError) {
        const body = JSON.stringify(data);
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/phone/verification/v2/generate_pre_delete`;
        post(url, headers, body, callback, onFailure, onError);
    }

    static phoneVerifyPreDelete(data, callback, onFailure, onError) {
        const body = JSON.stringify(data);
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/phone/verification/v2/verify_pre_delete`;
        post(url, headers, body, callback, onFailure, onError);
    }

    static signUp(user, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) return;
        const body = JSON.stringify(user);
        const url = `${restBase}/auth/signupV2`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static signUpRecaptcha(user, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) return;
        const body = JSON.stringify(user);
        const url = `${restBase}/auth/recaptcha/signup`;
        post(url, {}, body, callback, onFailure, onError);
    }

    static socialSignOn(
        data,
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        const body = JSON.stringify(data);
        const headers = {};
        const url = `${restBase}/auth/social/sso`;
        return post(url, headers, body, callback, onFailure, onError);
    }

    /** Braintree */

    static addBraintreeCard({ nonce }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ brainTreePaymentNonce: nonce });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/cards/v2`;
            post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getBraintreeSavedCards(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/cards/v2`;
            get(url, headers, callback, onFailure, onError);
        }
    }

    static getRecentPaymentMethod(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/recent/payment/v2`;
            get(url, headers, callback, onFailure, onError);
        }
    }

    /** Bundles **/

    static applyDiscount(
        params,
        callback = emptyFunction,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const { bundleId, code } = params;
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/bundles/discount/check?bundleId=${bundleId}&discountCode=${code}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getBundles(params, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const { recipientUserId, zipCode } = params;
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/bundles/v2?recipientUserId=${recipientUserId}&zipCode=${zipCode}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static purchaseBundle(data, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const { bundleId, discount, paymentId, paymentMethod } = data;
            const { code, status } = discount;
            const discountPayload =
                status === "active" ? { discountCode: code } : {};
            const body = JSON.stringify({
                paymentId,
                paymentMethod,
                ...discountPayload,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/bundles/${bundleId}/purchase/v2`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static purchaseBundleV3(
        { bundleId, discount, paymentMethod, token },
        callback,
        onFailure,
        onError
    ) {
        const { code, status } = discount;
        const discountPayload =
            status === "active" ? { discountCode: code } : {};
        const body = JSON.stringify({
            paymentMethod,
            paymentToken: token,
            ...discountPayload,
        });
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/bundles/${bundleId}/purchase/v3`;
        return post(url, headers, body, callback, onFailure, onError);
    }

    /** Dashboard */

    static getDashboard(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/dashboard`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getNewsItems(
        { page = 1, pageSize = 20 } = {},
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const params = `?page=${page}&pageSize=${pageSize}`;
            const url = `${restBase}/wordpress/news${params}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    /** Dep */

    static consumeDepUpdate({ weeklyUpdates }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const { contentNumber } = weeklyUpdates.selected;
            const body = JSON.stringify({
                branch: "Navy",
                contentNumber,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/dep/content/consumption`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getDepLocations({ branchId }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const depPath = generateDepLocationsUrl(branchId);
            if (branchId && depPath) {
                const headers = SandboxxRestAPI.restAuthHeader();
                const url = `${restBase}/${depPath}`;
                return get(url, headers, callback, onFailure, onError);
            }
        }
    }

    static setDepLocation({ depsShortCode }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ depsShortCode });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/dep/location`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getDepUpdatesConsumed(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/dep/content/consumption`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    /** Graduation **/

    static getGraduation(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/graduation`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    /**
     * * The `requestedWeeksUntil` key is spelled with a typo
     */
    static setGraduationWeek(
        { gradJointId, requestedWeeksUntil },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                gradJointId,
                requestedWeeksUtil: requestedWeeksUntil,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/graduation`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Digital Wallet **/

    static addGiftCardToWalletWithBarcode(
        { giftCardNumber, pin },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                giftCardNumber,
                pin,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/wallet/giftcards/add`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getWalletSuggestedContacts(callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/wallet/giftcards/v2/suggested_contacts`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getWalletGiftCardOptions(
        { provider },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const providerQuery = provider ? `?provider=${provider}` : "";
            const url = `${restBase}/wallet/giftcards/purchase${providerQuery}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getWalletGiftCardOptionsV2(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/wallet/giftcards/v2/options`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getWalletGiftCards({ ownershipType }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const ownershipTypeQuery = ownershipType
                ? `?ownershipType=${ownershipType}`
                : "";
            const url = `${restBase}/wallet/giftcards${ownershipTypeQuery}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static purchaseWalletGiftCard(
        { giftcard, payment },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                giftcard,
                payment,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/wallet/giftcards/purchase`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static purchaseWalletGiftCardV2(payload, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(payload);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/wallet/giftcards/v2/purchase`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Interstitial */

    static getInterstitialByName({ name }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/interstitial?name=${name}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getInterstitialByScreen({ screen }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/interstitial?screen=${screen}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    /** Letters: Compose **/

    static getSandboxxPlusStatus(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/subscriptions?status=ACTIVE`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getMultiphotoStatus(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/options`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    /**** Letters: Add-Ons ****/

    static getAddOns(payload, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(payload);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/addons`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getGiftCardOptions({ zipCode }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/giftcards?zipcode=${zipCode}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    // TODO: Remove paymentMethod hardcoding when options expand
    static purchaseAddOns(
        { bools, giftCard, newsletters, postage, sentLetter },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const {
                hasAddOnsTotal,
                hasPurchasedGiftCard,
                hasSelectedAddOns,
                hasSelectedPostage,
            } = bools;
            const { amount, braintree, purchased } = giftCard;
            const { sponsor } = purchased;
            const { token } = braintree;
            const { letterId } = sentLetter;

            const giftCardPayload = hasPurchasedGiftCard
                ? {
                      giftcard: {
                          sponsor,
                          value: amount.base.toString(),
                      },
                  }
                : {};
            const newslettersPayload = hasSelectedAddOns ? { newsletters } : {};
            const postagePayload = hasSelectedPostage
                ? { postage: postage[0] }
                : {};
            const payment =
                hasAddOnsTotal || hasPurchasedGiftCard
                    ? {
                          payment: {
                              paymentMethod: "BRAINTREE",
                              paymentToken: token,
                          },
                      }
                    : {};

            const body = JSON.stringify({
                ...giftCardPayload,
                ...newslettersPayload,
                ...postagePayload,
                ...payment,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${letterId}/addon/purchase`;
            post(url, headers, body, callback, onFailure, onError);
        }
    }

    // TODO: Remove paymentMethod hardcoding when options expand
    static purchaseGiftCard(
        { giftCard, letterId },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const { amount, purchased, braintree } = giftCard;
            const { fee, sponsor } = purchased;
            const { token: paymentToken } = braintree;
            const feeCalculated = amount.base * (fee * 0.01);
            const body = JSON.stringify({
                giftcard: {
                    fee: feeCalculated.toString(),
                    sponsor,
                    value: amount.base.toString(),
                },
                payment: {
                    paymentMethod: "BRAINTREE",
                    paymentToken,
                },
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${letterId}/giftcard/purchase/v2`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    /**** Letters: Address Formatter ****/

    static getAddressFormatterBases(
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/bases`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getAddressFormatterForm(
        baseId,
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/address/forms/${baseId}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static submitAddressFormatterForm(
        form,
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/address/forms/submit`;
            return post(
                url,
                headers,
                JSON.stringify(form),
                callback,
                onFailure,
                onError
            );
        }
    }

    /**** Letters: Resolution ****/

    static getExampleAddresses({ zipcode }) {
        if (CurrentUser.isLoggedIn()) {
            const url = `${restBase}/letters/address/examples?zipcode=${zipcode}`;
            const headers = SandboxxRestAPI.restAuthHeader();
            return getV2(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    static updateLetterAddress({ callback, letterId, onFailure, payload }) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(payload);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${letterId}`;
            return put(url, headers, body, callback, onFailure, onFailure);
        }
    }

    /** Iterable **/

    /**
     * @returns {Promise}
     */
    static getIterableEmbeddedMessages() {
        if (CurrentUser.isLoggedIn()) {
            const url = `${restBase}/iterable/embedded-messaging/messages`;
            const headers = SandboxxRestAPI.restAuthHeader();
            return getV2(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /**
     * @param {Function} callback
     * @param {Function} onFailure
     * @returns {Promise}
     */
    static getIterableJWT(callback, onFailure) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                emailSignature: true,
                platform: "WEB",
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/iterable/jwt`;
            return post(url, headers, body, callback, onFailure, onFailure);
        }
    }

    /**
     * @param {Object} payload
     *  @param {String?} buttonId
     *  @param {String} messageId
     *  @param {String} targetUrl
     * @param {Function} callback
     * @param {Function} onFailure
     * @returns {Promise}
     */
    static trackIterableEmbeddedMessageClickEvent({
        payload,
        callback,
        onFailure,
    }) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(payload);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/iterable/embedded-messaging/events/click`;
            return post(url, headers, body, callback, onFailure, onFailure);
        }
    }

    /**
     * @param {Object} payload
     *  @param {String} messageId
     * @param {Function} callback
     * @param {Function} onFailure
     * @returns {Promise}
     */
    static trackIterableEmbeddedMessageReceivedEvent(
        payload,
        callback,
        onFailure
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(payload);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/iterable/embedded-messaging/events/received`;
            return post(url, headers, body, callback, onFailure, onFailure);
        }
    }

    /** Letters **/

    static createLetterDraft(
        body,
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/draft`;
        post(url, headers, JSON.stringify(body), callback, onFailure, onError);
    }

    static getLetterDraft(
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/draft`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getLetterTracking(trackingId, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/track?tracking_code=${trackingId}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getLetterRepliedTracking({ id }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${id}/track/replied`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getLetterSentTrackingV2({ id }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${id}/track/sent`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getKinConnections(
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations/kin`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getLettersReplied(cursor, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/replied?cursor=${cursor}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getLettersSent(cursor, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters?cursor=${cursor}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getRecentContacts(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/contacts`;
            get(url, headers, callback, onFailure, onError);
        }
    }

    static getTitles(
        { zipcode },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const zipcodeQuery = zipcode ? `?zipcode=${zipcode}` : "";
            const url = `${restBase}/letters/recommended/titles${zipcodeQuery}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static markRepliedLetterAsDelivered(
        { mailboxxOrderId },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${mailboxxOrderId}/replied/delivered`;
            return put(url, headers, callback, onFailure, onError);
        }
    }

    static markRepliedLetterAsUndelivered(
        { mailboxxOrderId },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/${mailboxxOrderId}/replied/undelivered`;
            return put(url, headers, callback, onFailure, onError);
        }
    }

    static searchForUser({ role = "", term }, callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/search?role=${role}&term=${term}`;
        get(url, headers, callback, onFailure, onError);
    }

    static sendLetter(
        payload,
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(payload);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/letters/create`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateLetterDraft(
        body,
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/letters/draft/${body.id}`;
        put(url, headers, JSON.stringify(body), callback, onFailure, onError);
    }

    /** Personas **/

    static deletePersonas(callback, onError, onFailure) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({});
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/personas`;
            return deleteCall(url, headers, body, callback, onFailure, onError);
        }
    }

    static getChildPersonas({ id }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/personas/${id}/children`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getPersonas(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/personas`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getRootPersonas(callback, onError, onFailure) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/personas/root`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static updatePersona(personas, callback, onError, onFailure) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ personas });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/personas`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Muster **/

    static createMusterChat(
        { channelName, userList },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                channelName,
                userList,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/channel/create`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static addMusterChatMembers(
        { userList, channelUrl },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                userList,
                channelUrl,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/channel/add_user`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static addMusterDocument(
        { categoryId, description },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                categoryId,
                description,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/document`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static deleteMusterDocument({ documentId }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({});
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/document/${documentId}`;
            return deleteCall(url, headers, body, callback, onFailure, onError);
        }
    }

    static getMagicLink(
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations/muster/code`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getMusterRecruiters(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations/muster/recruiter`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getMusterRecruits(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations/muster/recruit`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getRecruitDashboard(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/recruit/dashboard`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getRecruiterDashboard(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/recruiter/dashboard`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static joinMusterRecruit(musterInviteCode, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ musterInviteCode });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations/muster/redeem`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static generateMusterAccessToken(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/token`;
            return post(url, headers, null, callback, onFailure, onError);
        }
    }

    static submitMusterRecruitEmailInvites(
        { emailList = [] },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                emailList,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations/muster/invite`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateMusterDocument(
        { categoryId, id, description, completed },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                categoryId,
                description,
                completed,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/muster/document/${id}`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Profile **/

    static deleteAccount(callback, deleteAuth, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ ...deleteAuth });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/account`;
            return deleteCall(url, headers, body, callback, onFailure, onError);
        }
    }

    static getAccount(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/meV2`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getConnections(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/associations`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getCredits(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/credits`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getProfile(
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/account`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static setUserShipDate(
        { baseId, shipDate },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ baseId, shipDate });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/shipdate`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateUserAddress(
        { address, firstName, lastName },
        callback,
        onFailure,
        onError
    ) {
        const body = JSON.stringify({
            ...removeNullUndefinedEmptyFromObject(address),
            firstName,
            lastName,
        });
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/address/v2`;
        put(url, headers, body, callback, onFailure, onError);
    }

    static updateUserAvatar(avatarUrl, callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/avatar`;
        const body = JSON.stringify({ avatarS: avatarUrl });
        put(url, headers, body, callback, onFailure, onError);
    }

    static updateUserBirthday({ birthday }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ value: birthday });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/birthday`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateUserBranch({ branch }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ branch });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/update/branch`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateUserFirstName({ firstName }, callback, onFailure, onError) {
        const body = JSON.stringify({ value: firstName });
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/firstname`;
        put(url, headers, body, callback, onFailure, onError);
    }

    static updateUserFullName(
        { firstName, lastName },
        callback = emptyFunction,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        const body = JSON.stringify({ firstName, lastName });
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/fullname`;
        put(url, headers, body, callback, onFailure, onError);
    }

    static updateUserGender({ gender }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ value: gender });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/gender`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateUserLastName({ lastName }, callback, onFailure, onError) {
        const body = JSON.stringify({ value: lastName });
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/lastname`;
        put(url, headers, body, callback, onFailure, onError);
    }

    static updateUserMaritalStatus(
        { maritalStatus },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ value: maritalStatus });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/maritalstatus`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateUserRank({ rank }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ value: rank });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/rank`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateUserRole(
        { role },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ role });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/update/role`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Referrals **/

    static claimReferralCode(referralCode, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ referralCode });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/referral`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getReferralRewards(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/rewards`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getReferralCode(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/referral`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getReferralStats(
        { userId },
        callback,
        onFailure = emptyFunction,
        onError = emptyFunction
    ) {
        const url = `${restBase}/users/referral_info/${userId}`;
        return get(url, {}, callback, onFailure, onError);
    }

    static sendReferralEmails(body, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/referral/email`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Store **/

    static applyDiscountCode(
        { cart, discountCode },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({});
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books/${cart.id}/discount-code/${discountCode}`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static completeCartSession({ cart }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({});
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books/${cart.id}/complete`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getCartSession(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getShippingOptions({ cart }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn() && cart) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books/${cart.id}/shipping-options`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static submitPaymentDetails(
        { billingAddress, cart, paymentMethodNonce, paymentMethodToken },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ billingAddress, paymentMethodToken });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books/${cart.id}/payment`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static submitShippingAddress(
        { cart, shippingAddress },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ shippingAddress });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books/${cart.id}/shipping-address`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static submitShippingOption(
        { address, cart, shippingOptionId },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ address, shippingOptionId });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books/${cart.id}/shipping-options`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static startCartSession(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({});
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateCartBookQuantity({ quantity }, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ quantity });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/store/memory-books`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Support Squad **/

    static donateTokens(
        { squadId, tokenDonationAmount },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ squadId, tokenDonationAmount });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad/donate`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getSupportSquadItem(squadId, callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/support_squad?squadId=${squadId}`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getSupportSquadList(callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/support_squad/list`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getSupportSquadMembers(squadId, callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/support_squad/list/users?squadId=${squadId}`;
        return get(url, headers, callback, onFailure, onError);
    }

    static createSupportSquad(squad, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(squad);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static deleteSupportSquad(squadId, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ squadId: squadId });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad`;
            return deleteCall(url, headers, body, callback, onFailure, onError);
        }
    }

    static joinSupportSquad(
        supportSquadInviteCode,
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ supportSquadInviteCode });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad/join`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateSupportSquadMemberRole(
        squadId,
        userToPromote,
        newRole,
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({ squadId, userToPromote, newRole });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad/role_change`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    static removeSupportSquadMember(
        leavingUserId,
        squadId,
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = leavingUserId
                ? JSON.stringify({ squadId, leavingUserId })
                : JSON.stringify({ squadId });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad/leave`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static submitSupportSquadEmailInvites(
        { squadId, supportSquadEmails = [], supportSquadUserIds = [] },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify({
                squadId,
                supportSquadEmails,
                supportSquadUserIds,
            });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad/invite`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static updateSupportSquad(squad, callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const body = JSON.stringify(squad);
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/support_squad`;
            return put(url, headers, body, callback, onFailure, onError);
        }
    }

    /** Users **/

    static getUserById(userId, callback, onFailure, onError) {
        const headers = SandboxxRestAPI.restAuthHeader();
        const url = `${restBase}/users/${userId}`;
        return get(url, headers, callback, onFailure, onError);
    }

    static getUserOrderHistory({ cursor, userId }) {
        if (CurrentUser.isLoggedIn()) {
            const cursorQueryParam = cursor ? `?cursor=${cursor}` : "";
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/${userId}/orders${cursorQueryParam}`;
            return getV2(url, headers);
        }
        return Promise.reject("User is not logged in");
    }

    /** Weekly Updates **/

    static consumeWeeklyUpdate(
        { weeklyUpdates },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const { graduation, selected } = weeklyUpdates;
            const { specialization, trainingBase } = graduation[0];
            const { week } = selected;
            const graduationTrackCode = specialization
                ? `${trainingBase}-${specialization}`.toLowerCase()
                : trainingBase.toLowerCase();
            const body = JSON.stringify({ graduationTrackCode, week });
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/weekly_updates/consumed`;
            return post(url, headers, body, callback, onFailure, onError);
        }
    }

    static getWeeklyUpdates(
        { baseSpecialization },
        callback,
        onFailure,
        onError
    ) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const params = `?weeklyUpdateTrack=${baseSpecialization}`;
            const url = `${restBase}/wordpress/weekly_updates${params}`;
            return get(url, headers, callback, onFailure, onError);
        }
    }

    static getWeeklyUpdatesConsumed(callback, onFailure, onError) {
        if (CurrentUser.isLoggedIn()) {
            const headers = SandboxxRestAPI.restAuthHeader();
            const url = `${restBase}/users/weekly_updates/consumed`;
            return get(url, headers, callback, onFailure, onError);
        }
    }
}
