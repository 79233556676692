import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

// Context
import { ContactsContext } from "context/contacts";
import { NotificationBarContext } from "context/notificationBar";
import { PhoneVerificationContext } from "context/phoneVerification";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

// Hooks
import { useDebounceValue } from "../../../hooks/debounce/useDebounceValue";

// Logging
import { AnalyticsLogger } from "../../../loggers/AnalyticsLogger";

// Models
import { Contact } from "models/contacts/Contact";

// Utils
import { SandboxxRestAPI } from "../../../utils/sandboxx";

export const useUserSearch = (props) => {
    const { setIsSearching } = props;

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { toggleContactModal, setOnSubmitContactSuccessFinalCallback } =
        useContext(ContactsContext);
    const { showNotification } = useContext(NotificationBarContext);
    const { togglePhoneVerificationModal } = useContext(
        PhoneVerificationContext
    );

    /**
     * useState
     */

    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({
        role: "",
        term: "",
        users: [],
        validations: {
            hasResults: false,
        },
    });

    const { term, users } = search;

    /**
     * useDebounce
     */

    const debouncedSearchTerm = useDebounceValue(term, 500);

    /**
     * useEffect
     */

    useEffect(() => {
        if (term) {
            setIsSearching(true);
            fetchSearchResults();
        } else {
            setIsSearching(false);
            setSearch((prevSearch) => ({ ...prevSearch, users: [] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (search.users) {
            validateSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search.users]);

    /**
     * End Hooks
     */

    function fetchSearchResults() {
        const { role, term } = search;
        toggleLoading(true);
        SandboxxRestAPI.searchForUser(
            { role, term },
            onSearchSuccess,
            onSearchFailure,
            onSearchError
        );
    }

    function handleSearchInputChange(e) {
        e.preventDefault();
        const { value } = e.target;
        setSearch((prevSearch) => ({ ...prevSearch, term: value }));
    }

    function handleToggleContactModal() {
        AnalyticsLogger.logNewContactView();
        toggleContactModal({
            contactToEdit: {
                firstName: "",
                fullName: "",
                lastName: "",
                title: "",
                address: {
                    line1: "",
                    line2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                },
            },
            isSupportSquad: false,
            isWritingLetter: true,
            shouldShow: true,
            targetScreen: PersonModalNavigation.NAME,
            type: "create",
        });
        setOnSubmitContactSuccessFinalCallback(() => onCreateContactSuccess);
    }

    function onCreateContactSuccess(res) {
        const contact = new Contact(res);
        localStorage.setItem(
            "sandboxxMessageRecipient",
            JSON.stringify(contact.generateContactObject())
        );
        history.replace(`/letters`);
        history.replace(`/letters/compose`);
    }

    function onSearchError(err) {
        showNotification({
            text: "An error was encountered when searching for a user. Please refresh the page and try again.",
            type: "warning",
        });
        toggleLoading(false);
    }

    function onSearchFailure(err) {
        validateSearch();
        toggleLoading(false);
    }

    /**
     *
     * * A `recipientId` is attached to each search results so that they are marked as
     * * Sandboxx users when processed by the Contact model
     */
    function onSearchSuccess(users) {
        const usersWithRecipientId = users.map((user) => ({
            ...user,
            recipientId: user.id,
        }));
        updateSearchResults(usersWithRecipientId);
        toggleLoading(false);
    }

    function toggleLoading(isLoading) {
        setIsLoading(isLoading);
    }

    function updateSearchResults(users) {
        setSearch((prevSearch) => ({ ...prevSearch, users }));
    }

    function validateSearch() {
        const hasResults = users.length > 0;
        setSearch((prevSearch) => ({
            ...prevSearch,
            validations: {
                ...prevSearch.validations,
                hasResults,
            },
        }));
    }

    return {
        handleSearchInputChange,
        handleToggleContactModal,
        isLoading,
        search,
        togglePhoneVerificationModal,
    };
};
