import React from "react";
import { withRouter } from "react-router-dom";

// Components
import { PersonModal } from "components/PersonModal/PersonModal";

// Hooks
import { useContactModal } from "./hooks/useContactModal";

export const ContactModal = () => {
    /**
     * Custom Hooks
     */

    const {
        bases,
        contactModal,
        contactStaging,
        currentSection,
        exampleAddresses,
        handleChange,
        handleCloseModal,
        handleFormGoBack,
        handleSubmit,
        handleSubmitAddressFormatter,
        headerText,
        headerTextOptions,
        loading,
        setCurrentSection,
        setHeaderText,
        shouldReverseFullName,
        titles,
        toggleShouldReverseFullName,
    } = useContactModal();

    /**
     * Constants
     */

    const { shouldShow, shouldShowBackButton } = contactModal;

    /**
     * End Hooks & Constants
     */

    return (
        <PersonModal
            bases={bases}
            config={{
                shouldEnableStateFieldToggle: false,
                shouldHideFormAddressFormatterBanner: false,
                shouldHideFormAddressPreview: false,
                shouldHideFormNameToggle: false,
                shouldHideFormTitleField: false,
                shouldShowFormBackButton: shouldShowBackButton,
                shouldShowFormCountryField: false,
            }}
            currentSection={currentSection}
            exampleAddresses={exampleAddresses}
            headerText={headerText}
            headerTextOptions={headerTextOptions}
            loading={loading}
            onChange={handleChange}
            onCloseModal={handleCloseModal}
            onFormGoBack={handleFormGoBack}
            onSubmit={handleSubmit}
            onSubmitAddressFormatter={handleSubmitAddressFormatter}
            personStaging={contactStaging}
            setCurrentSection={setCurrentSection}
            setHeaderText={setHeaderText}
            shouldReverseFullName={shouldReverseFullName}
            shouldShow={shouldShow}
            titles={titles}
            toggleShouldReverseFullName={toggleShouldReverseFullName}
        />
    );
};

export const WrappedContactModal = withRouter(ContactModal);
