import PropTypes from "prop-types";

// Components
import { ButtonSecondary } from "components/Buttons/Buttons";
import { SaveIcon } from "components/Icons/SaveIcon";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { calculateRemainingMessageCharacters } from "utils/lettersUtils";
import { emptyFunction } from "utils/objectUtils";

export const LettersDraftSaveButton = ({
    handleInitializeSaveDraft,
    message,
    isSavingDraft,
    setIsSavingDraft,
}) => {
    /**
     * Custom Hooks
     **/

    const { t } = useTranslation("letters");

    /**
     * End Hooks
     */

    const { remainingCharacters } = calculateRemainingMessageCharacters(
        message.content
    );
    const isDisabled = remainingCharacters < 0;
    const saveButtonOnClick = isSavingDraft ? emptyFunction : onClick;

    function onClick() {
        handleInitializeSaveDraft(true);
        AnalyticsLogger.logLetterComposeSaveDraft();
        setIsSavingDraft(true);
    }

    return (
        <ButtonSecondary
            classes="letters-draft-save-button"
            cypressTestId="letters-draft-save-button"
            isDisabled={isDisabled}
            isLoading={isSavingDraft}
            onClick={saveButtonOnClick}
        >
            <SaveIcon
                className="icon"
                color={isDisabled ? "var(--gray-light)" : "#2B536A"}
            />
            {t("save", { ns: "common" })}
        </ButtonSecondary>
    );
};

LettersDraftSaveButton.defaultProps = {
    handleDraftSave: emptyFunction,
    isSavingDraft: false,
};

LettersDraftSaveButton.propTypes = {
    handleDraftSave: PropTypes.func.isRequired,
    message: PropTypes.object.isRequired,
    isSavingDraft: PropTypes.bool.isRequired,
    setIsSavingDraft: PropTypes.func.isRequired,
};
