import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { AddressFormatter } from "components/AddressFormatter/AddressFormatter";
import { AutocompleteGrouped } from "components/AutocompleteGrouped/AutocompleteGrouped";
import { Banner } from "components/Banner/Banner";

// CSS
import styles from "./person-modal-address-formatter.module.scss";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

export const PersonModalAddressFormatter = ({
    bases,
    handleSubmitAddressFormatter,
    headerTextOptions,
    setCurrentSection,
    setHeaderText,
}) => {
    /**
     * useState
     */

    const [selectedBase, setSelectedBase] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderText(headerTextOptions.addressFormatter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    function handleSelectBase(e, base) {
        setSelectedBase(base);
    }

    return (
        <div className={styles.personModalAddressFormatter}>
            <AutocompleteGrouped
                className={styles.baseSelect}
                cypressTestId="person-modal-address-formatter-autocomplete-grouped"
                getOptionLabel={(option) => option.name}
                groupBy={(option) => option.branch}
                label="Select your recipient's base"
                onChange={handleSelectBase}
                options={bases}
            />
            <Banner>
                Your letter will be shipped the same day as long as it is
                submitted before 4:30 pm EST. Letters submitted on Friday will
                be scheduled for delivery on Monday. We send letters via FedEx
                to boot camp locations only. All other letters will be sent
                USPS.
            </Banner>
            <AddressFormatter
                classNames={{
                    buttons: styles.buttons,
                    container: styles.addressFormatter,
                    panel: styles.panel,
                }}
                base={selectedBase}
                handleSectionChange={(e) =>
                    setCurrentSection(PersonModalNavigation.ADDRESS_MANUAL)
                }
                handleSubmit={handleSubmitAddressFormatter}
            />
        </div>
    );
};

PersonModalAddressFormatter.propTypes = {
    bases: PropTypes.array,
    handleSubmitAddressFormatter: PropTypes.func.isRequired,
    headerTextOptions: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    setHeaderText: PropTypes.func.isRequired,
};
