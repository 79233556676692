import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Components
import { Select } from "components/Inputs/Select/Select";

// Constants
import { stateOptionsData } from "./constants/constants";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const DropdownState = ({
    className,
    cypressTestId,
    label,
    name,
    onChange,
    required,
    shouldUseStatesOnly,
    size,
    value,
}) => {
    /**
     * useMemo
     */

    const optionsData = useMemo(
        () =>
            shouldUseStatesOnly
                ? stateOptionsData.filter((item) => item.isState)
                : stateOptionsData,
        [shouldUseStatesOnly]
    );

    /**
     * End Hooks & Constants
     */

    function renderOptions() {
        return optionsData.map((state, i) => {
            return (
                <option key={i} value={state.value}>
                    {state.display} - {state.name}
                </option>
            );
        });
    }

    return (
        <Select
            className={className}
            cypressTestId={cypressTestId}
            label={label}
            name={name}
            onChange={onChange}
            required={required}
            size={size}
            value={value}
        >
            <option value={null} />
            {renderOptions()}
        </Select>
    );
};

DropdownState.defaultProps = {
    className: "",
    label: "State",
    onChange: emptyFunction,
    shouldUseStatesOnly: false,
    size: "normal",
};

DropdownState.propTypes = {
    className: PropTypes.string,
    cypressTestId: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    shouldUseStatesOnly: PropTypes.bool,
    size: PropTypes.string,
    value: PropTypes.string,
};
