import React from "react";
import cx from "classnames";
import { Skeleton } from "@material-ui/lab";

// Components
import AddIcon from "@mui/icons-material/Add";
import { Panel } from "components/Panel/Panel";

// CSS
import styles from "./dashboard-credits.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { handleKeyDown } from "utils/eventUtils";
import { emptyFunction } from "utils/objectUtils";

export const DashboardCredits = ({
    bundles,
    credits,
    handlePurchaseModalToggle,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */
    const areBundlesAvailable = bundles?.length;
    const getMoreClickHandler = areBundlesAvailable
        ? handlePurchaseModalToggle
        : emptyFunction;

    function renderCreditsNumber() {
        if (credits !== null) {
            return (
                <div className={styles.number}>
                    <span>{credits}</span>
                </div>
            );
        } else {
            return (
                <div className={styles.number}>
                    <Skeleton className={styles.loading} />
                </div>
            );
        }
    }

    return (
        <Panel classes={styles.dashboardCredits}>
            <div className="panel__header">
                <span className="panel__header__text">
                    {t("letter_ip_plural", { ns: "common" }).toUpperCase()}
                </span>
            </div>
            <div
                className={styles.body}
                data-cy="dashboard-purchase-modal-button"
                onClick={getMoreClickHandler}
                onKeyDown={(e) => handleKeyDown(e, getMoreClickHandler)}
                tabIndex="0"
            >
                <div className={styles.remaining}>
                    {renderCreditsNumber()}
                    <div className={styles.text}>
                        <span>{t("letter_ip_plural", { ns: "common" })}</span>
                        <span>{t("remaining", { ns: "common" })}</span>
                    </div>
                </div>
                <div
                    className={cx(styles.more, {
                        [styles["more--disabled"]]: !areBundlesAvailable,
                    })}
                >
                    <AddIcon className={styles.icon} />
                </div>
            </div>
        </Panel>
    );
};
