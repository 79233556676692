import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Panel } from "components/Panel/Panel";
import { Select } from "components/Inputs/Select/Select";
import { TextField } from "components/Inputs/TextField/TextField";

// CSS
import styles from "./address-formatter-input.module.scss";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { titleCase } from "utils/textUtils";

export const AddressFormatterInput = ({
    classNames,
    formBlock,
    formStack,
    handleInputChange,
    handleSubmitAddressForm,
    isSubmitting,
    index,
}) => {
    function renderInput() {
        const { fields } = formBlock;
        const onSubmit = isSubmitting ? emptyFunction : handleSubmitAddressForm;
        return (
            <form className={cx(styles.form, "form")} onSubmit={onSubmit}>
                {fields.map((input, i) => renderInputField(input, i))}
            </form>
        );
    }

    function renderInputField(field, fieldIndex) {
        switch (field.dataType) {
            case "ALPHA_NUMERIC":
                return (
                    <div className={styles.field} key={fieldIndex}>
                        <TextField
                            className={styles.input}
                            cypressTestId="address-formatter-input-text"
                            label={titleCase(field.hint)}
                            onChange={(e) =>
                                handleInputChange(e, {
                                    fieldIndex,
                                    formStack,
                                    index,
                                })
                            }
                            required={field.required}
                            type="text"
                            value={field.value}
                        />
                    </div>
                );
            case "NUMERIC":
                return (
                    <div className={styles.field} key={fieldIndex}>
                        <TextField
                            className={styles.input}
                            cypressTestId="address-formatter-input-number"
                            label={titleCase(field.hint)}
                            onChange={(e) =>
                                handleInputChange(e, {
                                    fieldIndex,
                                    formStack,
                                    index,
                                })
                            }
                            required={field.required}
                            type="number"
                            value={field.value}
                        />
                    </div>
                );
            case "OPTIONS":
                const options = field.options.map(({ name, value }, i) => (
                    <option key={i} value={value}>
                        {name}
                    </option>
                ));
                return (
                    <div className={styles.field} key={fieldIndex}>
                        <Select
                            className={styles.input}
                            cypressTestId="address-formatter-select"
                            label={titleCase(field.hint)}
                            name={field.name}
                            onChange={(e) =>
                                handleInputChange(e, {
                                    fieldIndex,
                                    formStack,
                                    index,
                                })
                            }
                            required={field.required}
                        >
                            <option value={null} />
                            {options}
                        </Select>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <Panel classes={cx(classNames.panel, styles.addressFormatterInput)}>
            {renderInput()}
        </Panel>
    );
};

AddressFormatterInput.propTypes = {
    classNames: PropTypes.shape({
        buttons: PropTypes.string,
        container: PropTypes.string,
        panel: PropTypes.string,
    }),
    formBlock: PropTypes.shape({
        description: PropTypes.string,
        fields: PropTypes.array,
        formId: PropTypes.string,
        isComplete: PropTypes.bool,
    }).isRequired,
    formStack: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSubmitAddressForm: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
};
