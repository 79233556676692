import { useContext } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { generateSubmitShippingAddressPayload } from "../utils/apiUtils";

export const useCartShippingAddress = ({
    cart,
    setCart,
    setIsUpdatingCart,
}) => {
    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * End Hooks
     */

    function onSubmitShippingAddressFailure(err, onFailure) {
        setIsUpdatingCart(() => false);
        showNotification({ text: err.message, type: "warning" });
        onFailure(err);
    }

    function onSubmitShippingAddressSuccess(res, onSuccess) {
        setCart(res.cart);
        setIsUpdatingCart(() => false);
        onSuccess(res);
    }

    function submitShippingAddress({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        shippingAddress,
    } = {}) {
        setIsUpdatingCart(() => true);
        const shippingAddressPayload = generateSubmitShippingAddressPayload({
            shippingAddress,
        });
        SandboxxRestAPI.submitShippingAddress(
            { cart, shippingAddress: shippingAddressPayload },
            (res) => onSubmitShippingAddressSuccess(res, onSuccess),
            (err) => onSubmitShippingAddressFailure(err, onFailure),
            (err) => onSubmitShippingAddressFailure(err, onFailure)
        );
    }

    return {
        submitShippingAddress,
    };
};
