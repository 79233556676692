import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// CSS
import styles from "./checkbox.module.scss";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const Checkbox = (props) => {
    const {
        children,
        classes,
        checked,
        cyId,
        id,
        onChange,
        shouldDisplayLabelInline,
        text,
        type,
    } = props;

    return (
        <label
            className={cx(styles.checkbox, classes, {
                [styles["checkbox--warning"]]: type === "warning",
                [styles["checkbox--inline"]]: shouldDisplayLabelInline,
            })}
            htmlFor={id}
        >
            <div className={styles.container}>
                <input
                    className={styles.input}
                    checked={checked}
                    id={id}
                    type="checkbox"
                    onChange={onChange}
                />
                <div
                    className={cx(styles.display, {
                        [styles["display--checked"]]: checked,
                    })}
                    data-cy={cyId}
                >
                    {type === "warning" ? (
                        <svg
                            className={cx(styles.icon, {
                                [styles["icon--checked"]]: checked,
                            })}
                            width="13"
                            height="3"
                            viewBox="0 0 13 3"
                            fill="none"
                        >
                            <path
                                d="M11.9177 0.666672H1.08522C0.625019 0.666672 0.251953 1.03977 0.251953 1.50001C0.251953 1.96024 0.625019 2.33334 1.08522 2.33334H11.9177C12.3779 2.33334 12.751 1.96024 12.751 1.50001C12.751 1.03977 12.3779 0.666672 11.9177 0.666672Z"
                                fill="#F3F2EF"
                            />
                        </svg>
                    ) : (
                        <svg
                            className={cx(styles.icon, {
                                [styles["icon--checked"]]: checked,
                            })}
                            viewBox="0 0 24 24"
                        >
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                    )}
                </div>
            </div>
            {text && <span className={styles.text}>{text}</span>}
            {!text && children && <div className={styles.text}>{children}</div>}
        </label>
    );
};

Checkbox.defaultProps = {
    classes: "",
    checked: false,
    cyId: "",
    id: "",
    onChange: emptyFunction,
    text: "",
};

Checkbox.propTypes = {
    classes: PropTypes.string,
    checked: PropTypes.bool,
    cyId: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    text: PropTypes.string,
};
