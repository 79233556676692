export function checkAndConvertToArray(item) {
    return Array.isArray(item) ? item : [item];
}

export function convertToISODate(string) {
    const date = new Date(string);
    return date.toISOString();
}

export function preventDefault(e) {
    e.preventDefault();
}
