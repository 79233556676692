import { useState } from "react";

// Utils
import { CurrentUser } from "utils/sandboxx";

export const useSender = () => {
    const [sender, setSender] = useState({});
    const [shouldShowSenderModal, setShouldShowSenderModal] = useState(false);

    function handleSetSender() {
        const user = CurrentUser.getUser();
        setSender({
            address: {
                city: user.city,
                country: user.country,
                line1: user.line1,
                line2: user.line2,
                state: user.state,
                zipcode: user.zipcode,
            },
            firstName: user.firstName,
            fullName: user.fullName,
            lastName: user.lastName,
        });
    }

    return {
        handleSetSender,
        sender,
        shouldShowSenderModal,
        setSender,
        setShouldShowSenderModal,
    };
};
