import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import {
    getFirestore,
    collection,
    doc,
    getDoc,
    limit,
    orderBy,
    query,
    getDocs,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

// Config
import { configDefault, configNews } from "../config/firebase";

// Utils
import { emptyFunction } from "utils/objectUtils";

class Firebase {
    constructor() {
        this.firebaseDefault = initializeApp(configDefault, "firebaseDefault");
        this.firebaseNews = initializeApp(configNews, "firebaseNews");
        this.auth = getAuth(this.firebaseNews);
        this.analytics = getAnalytics(this.firebaseDefault);
        this.db = getFirestore(this.firebaseNews);
        this.storage = getStorage(this.firebaseNews);
        this.authAnonymously();
    }

    /**
     * * Asynchronously signs into Firebase as an anonymous user
     * * Initializes onAuthStateChanged listener
     *
     * @param {Function} callback
     *
     * @return {Promise} UserCredential
     *
     * TODO: Consider whether callback is required in onAuthStateChanged callback
     */
    authAnonymously = (callback = emptyFunction) => {
        signInAnonymously(this.auth).catch((error) => {
            console.warn(error.message);
        });
        onAuthStateChanged(this.auth, (firebaseUser) => {
            if (firebaseUser) {
                callback(firebaseUser.uid);
            } else {
                callback(null);
            }
        });
    };

    /**
     * * Fetches full collection from Database
     *
     * @param {String} collection pathname for desired collection
     * @param {Object} options optional modifiers for collection call (e.g., orderBy, limit)
     *
     * @return {Array}
     *
     * TODO: Allow for orderBy with ascending direction (i.e., no second .orderBy parameter)
     */
    async getCollection(collections, options = {}) {
        const { limitProp, orderByProp } = options;
        let snapshot = query(collection(this.db, collections));

        if (orderByProp && limitProp) {
            snapshot = query(
                collection(this.db, collections),
                orderBy(orderByProp, "desc"),
                limit(limitProp)
            );
        } else if (options.orderByProp) {
            snapshot = query(
                collection(this.db, collections),
                orderBy(orderByProp, "desc")
            );
        } else if (options.limitProp) {
            snapshot = query(
                collection(this.db, collections),
                limit(limitProp)
            );
        }

        const snapshotFetched = await getDocs(snapshot);
        return snapshotFetched.docs.map((doc) => doc.data());
    }

    /**
     * * Fetches individual document from Database
     *
     * @param {String} collection pathname for collection containing desired document
     * @param {String} doc name of document
     *
     * @return {Object}
     */
    async getDocument(collections, docProp) {
        try {
            const docRef = doc(this.db, collections, docProp);
            return await getDoc(docRef).then((res) => res.data());
        } catch (error) {
            console.error("getDocument error", error);
            return {};
        }
    }

    /**
     * * Fetches a download url for a file in Storage
     *
     * @param {String} url url for the directory that contains the desired file
     * @param {String} name the name of the file for which you want a download url
     *
     * @return {String}
     */
    async getDownloadUrl(url, name) {
        const appendedUrl = url + "/" + name;
        const pathReference = ref(this.storage, appendedUrl);
        return getDownloadURL(pathReference)
            .then((url) => url)
            .catch((error) => {
                console.error("getDownloadUrl error", error);
            });
    }

    /**
     * * Fetches weekly updates from Firestore
     *
     * @param {Array} graduationData
     * @param {Function} callback
     *
     * TODO: Invoke function after getGraduationWeek is called in sandboxx.js
     */
    getWeeklyUpdates = (graduationData, callback) => {
        const graduationTrackTest = graduationData[0].trainingBase;
        const q = query(collection(this.db, "WeeklyUpdateTracks"));
        const querySnapshot = getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.id === graduationTrackTest) {
                callback(doc.data().weeklyUpdateTemplates);
            }
        });
    };

    trackEvent(eventName) {
        logEvent(this.analytics, eventName);
    }

    trackEventEcommerce(event, params) {
        switch (event) {
            case "addToCart":
                logEvent(this.analytics, "add_to_cart", params);
                return;
            case "beginCheckout":
                logEvent(this.analytics, "begin_checkout", params);
                return;
            case "purchase":
                logEvent(this.analytics, "purchase", params);
                return;
            case "removeFromCart":
                logEvent(this.analytics, "remove_from_cart", params);
                return;
            default:
                return;
        }
    }
}

export const firebase = new Firebase();
