import { useEffect, useState } from "react";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { emptyFunction } from "utils/objectUtils";

// Constants
import { WalletListTabIds } from "scenes/Wallet/constants/constants";
const { MY_GIFT_CARDS } = WalletListTabIds;

export const useWalletManageListCard = ({
    card,
    selectedGiftCard,
    selectedWalletListTabId,
}) => {
    /**
     * useState
     */

    const [imageSrc, setImageSrc] = useState("");

    /**
     * useEffect
     */

    // Fetch full gift card image once the imageUrl is available
    useEffect(() => {
        card.imageUrl && fetchGiftCardImage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card]);

    /**
     * Constants
     */

    const isSelected =
        selectedWalletListTabId === MY_GIFT_CARDS &&
        card.giftCardNumber === selectedGiftCard.giftCardNumber;

    /**
     * End Hooks
     */

    function fetchGiftCardImage() {
        SandboxxRestAPI.getImageCloudfront(
            card.imageUrl,
            (res) => setImageSrc(res),
            emptyFunction,
            emptyFunction,
            { fileType: "png" }
        );
    }

    return { imageSrc, isSelected };
};
