// Utils
import { DEV } from "./urlUtils";
import { emptyFunction } from "./objectUtils";

// Constants
const siteKey = DEV
    ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_DEV
    : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_PROD;

export function getRecaptchaToken(
    action,
    callback = emptyFunction,
    onFailure = emptyFunction
) {
    window.grecaptcha.enterprise
        .execute(siteKey, {
            action,
        })
        .then(function (recaptchaToken) {
            if (recaptchaToken) {
                callback(recaptchaToken);
            } else {
                onFailure();
                console.log("Error generating recaptcha");
            }
        });
}

export function loadRecaptchaScript() {
    const existingScript = document.getElementById("google-recaptcha");
    if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKey}`;
        script.id = "google-recaptcha";
        document.body.appendChild(script);
        script.onload = () => {};
    }
}
