import { useMemo, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { generateSubmitPaymentDetailsPayload } from "../utils/apiUtils";
import { emptyFunction } from "utils/objectUtils";

// Validation
import { Validation } from "../validation/Validation";

export const useCart = () => {
    /**
     * useState
     **/

    const [cart, setCart] = useState(null);
    const [completedOrder, setCompletedOrder] = useState(null);
    const [isSubmittingCart, setIsSubmittingCart] = useState(false);
    const [isUpdatingCart, setIsUpdatingCart] = useState(false);

    /**
     * useMemo
     **/

    const hasShippingAddress = useMemo(() => {
        return Validation.hasShippingAddress(cart);
    }, [cart]);

    /**
     * End Hooks
     **/

    function applyDiscountCode({ discountCode, onFailure, onSuccess }) {
        setIsUpdatingCart(() => true);
        SandboxxRestAPI.applyDiscountCode(
            { cart, discountCode },
            (res) => onUpdateCartSuccess(res, onSuccess),
            (err) => onUpdateCartFailure(err, onFailure),
            (err) => onUpdateCartFailure(err, onFailure)
        );
    }

    function completeCartSession({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        setIsSubmittingCart(() => true);
        setIsUpdatingCart(() => true);
        SandboxxRestAPI.completeCartSession(
            { cart },
            (res) => onCompleteCartSessionSuccess(res, onSuccess),
            (err) => onCompleteCartSessionFailure(err, onFailure),
            (err) => onCompleteCartSessionFailure(err, onFailure)
        );
    }

    function onCompleteCartSessionFailure(err, onFailure) {
        setIsSubmittingCart(() => false);
        setIsUpdatingCart(() => false);
        onFailure(err);
    }

    function onCompleteCartSessionSuccess(res, onSuccess) {
        setCompletedOrder(res.cart.data);
        setIsSubmittingCart(() => false);
        setIsUpdatingCart(() => false);
        onSuccess(res);
    }

    function getCartSession({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        setIsUpdatingCart(() => true);
        SandboxxRestAPI.getCartSession(
            (json, response) => onUpdateCartSuccess(json, onSuccess, response),
            (err, response) => onUpdateCartFailure(err, onFailure, response),
            (err, response) => onUpdateCartFailure(err, onFailure, response)
        );
    }

    function onUpdateCartFailure(err, onFailure, response) {
        setIsUpdatingCart(() => false);
        onFailure(err, response);
    }

    function onUpdateCartSuccess(res, onSuccess, response) {
        if (res?.cart) {
            setCart(() => res.cart);
        }
        setIsUpdatingCart(() => false);
        onSuccess(res, response);
    }

    function resetCartContext() {
        setCart(() => null);
        setCompletedOrder(() => null);
        setIsSubmittingCart(() => false);
        setIsUpdatingCart(() => false);
    }

    function submitPaymentDetails({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        paymentMethod,
    } = {}) {
        setIsUpdatingCart(() => true);
        const payload = generateSubmitPaymentDetailsPayload({
            paymentMethod,
        });
        SandboxxRestAPI.submitPaymentDetails(
            { cart, ...payload },
            (res) => onUpdateCartSuccess(res, onSuccess),
            (err) => onUpdateCartFailure(err, onFailure),
            (err) => onUpdateCartFailure(err, onFailure)
        );
    }

    function startCartSession({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
    } = {}) {
        return SandboxxRestAPI.startCartSession(
            (res) => onUpdateCartSuccess(res, onSuccess),
            (err) => onUpdateCartFailure(err, onFailure),
            (err) => onUpdateCartFailure(err, onFailure)
        );
    }

    function updateCartBookQuantity({
        onFailure = emptyFunction,
        onSuccess = emptyFunction,
        quantity,
    } = {}) {
        setIsUpdatingCart(() => true);
        return SandboxxRestAPI.updateCartBookQuantity(
            { quantity },
            (res) => onUpdateCartSuccess(res, onSuccess),
            (err) => onUpdateCartFailure(err, onFailure),
            (err) => onUpdateCartFailure(err, onFailure)
        );
    }

    return {
        applyDiscountCode,
        cart,
        completeCartSession,
        completedOrder,
        getCartSession,
        hasShippingAddress,
        isSubmittingCart,
        isUpdatingCart,
        resetCartContext,
        setCart,
        submitPaymentDetails,
        setIsUpdatingCart,
        startCartSession,
        updateCartBookQuantity,
    };
};
