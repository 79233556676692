import React from "react";

// Components
import { Banner } from "components/Banner/Banner";
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { PersonModalAddressManualExampleAddress } from "./components/PersonModalAddressManualExampleAddress/PersonModalAddressManualExampleAddress";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";

// CSS
import styles from "./person-modal-address-manual.module.scss";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

export const PersonModalAddressManual = ({
    exampleAddresses,
    personStaging,
    onChange,
    onSubmit,
    setCurrentSection,
}) => {
    const { city, line1, line2, state, zipcode } = personStaging.address;

    return (
        <div className={styles.personModalAddressManual} onSubmit={onSubmit}>
            <form className={styles.form}>
                <TextField
                    className={styles.input}
                    cypressTestId="person-modal-address-manual-line-1"
                    label="Address Line 1"
                    name="address.line1"
                    onChange={onChange}
                    required
                    type="text"
                    value={line1}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="person-modal-address-manual-line-2"
                    label="Address Line 2"
                    name="address.line2"
                    onChange={onChange}
                    required
                    type="text"
                    value={line2}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="person-modal-address-manual-city"
                    label="City"
                    name="address.city"
                    onChange={onChange}
                    required
                    type="text"
                    value={city}
                />
                <DropdownState
                    className={styles.input}
                    cypressTestId="person-modal-address-manual-state"
                    name="address.state"
                    onChange={onChange}
                    required
                    value={state}
                />
                <TextField
                    className={styles.input}
                    cypressTestId="person-modal-address-manual-zipcode"
                    label="Zip Code"
                    name="address.zipcode"
                    onChange={onChange}
                    required
                    type="text"
                    value={zipcode}
                />
                <PersonModalAddressManualExampleAddress
                    className={styles.banner}
                    exampleAddresses={exampleAddresses}
                    personStaging={personStaging}
                />
                <Banner classes={styles.banner}>
                    <Text>
                        The recipient address must be complete or your letter
                        will be placed on hold. We recommend using our address
                        formatter assistant on the previous screen.{" "}
                        <a
                            className={styles.link}
                            href="https://www.sandboxx.us/blog/how-to-get-the-address-of-someone-in-basic-training/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "underline" }}
                        >
                            Click here
                        </a>{" "}
                        to learn more about getting the correct recipient
                        address.
                    </Text>
                </Banner>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        cypressTestId="person-modal-address-manual-submit-button"
                        isDisabled={!city || !line1 || !state || !zipcode}
                        onClick={onSubmit}
                    >
                        <Text>Confirm Address</Text>
                    </ButtonPrimary>
                    <ButtonInvisible
                        onClick={() =>
                            setCurrentSection(
                                PersonModalNavigation.ADDRESS_FORMATTER
                            )
                        }
                    >
                        <Text>Back</Text>
                    </ButtonInvisible>
                </div>
            </form>
        </div>
    );
};
