import React, { memo, useEffect, useState } from "react";
import cx from "classnames";

// CSS
import styles from "./google-login-button.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { Google } from "../../../platforms/google";

// Utils
import { areObjectsEqualStringified } from "utils/objectUtils";

// Constants
const googleIconSrc = require("media/signin/google-icon.png").default;

export const GoogleLoginButton = memo(
    (props) => {
        const { callbacks, classes, mode, size, text } = props;

        /************************************************
         * useState
         ************************************************/

        const [buttonText, setButtonText] = useState(text);
        const [isLoading, setIsLoading] = useState(false);

        /************************************************
         * useEffect
         ************************************************/

        useEffect(
            () =>
                Google.initLogin({ ...callbacks, toggleButtonLoading }, true, {
                    mode,
                }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [callbacks]
        );

        /************************************************
         * End Hooks
         ************************************************/

        function handleGoogleLogin() {
            toggleButtonLoading(true);
            if (mode === "verify") {
                AnalyticsLogger.logDeleteAccountAuthSelected();
            } else {
                AnalyticsLogger.logSignUpStart("google", "selectGoogle");
            }
        }

        function toggleButtonLoading(bool) {
            const buttonText =
                isLoading && mode !== "verify" ? "Loading..." : text;
            setButtonText(buttonText);
            setIsLoading(bool);
        }

        return (
            <button
                className={cx(
                    styles["google-login-button"],
                    "button",
                    `button--size-${size}`,
                    classes,
                    { disabled: isLoading }
                )}
                id="google-login-button"
                onClick={handleGoogleLogin}
            >
                <img
                    alt="Google logo"
                    className={styles.icon}
                    src={googleIconSrc}
                />
                <span className={styles.text}>{buttonText}</span>
            </button>
        );
    },
    (prevProps, nextProps) => areObjectsEqualStringified(prevProps, nextProps)
);

GoogleLoginButton.defaultProps = {
    classes: "",
};
