/**
 * Check if two objects are equal
 *
 * @param {Object} object1 - The first object to compare
 * @param {Object} object2 - The second object to compare
 * @returns {boolean} Whether the objects are equal
 */
export function areObjectsEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !areObjectsEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

/**
 * Check if two objects are equal by stringifying them
 *
 * @param {Object} object1 - The first object to compare
 * @param {Object} object2 - The second object to compare
 * @returns {boolean} Whether the objects are equal
 */
export function areObjectsEqualStringified(object1, object2) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}

/**
 * Convert an object so that all keys are in snake case
 * TODO: Needs to be updated to support conversion of hyphen and space to underscore
 *
 * @param {Object} object - The object to convert
 * @returns {Object} The converted object
 */
export function convertObjectToSnakeCase(object) {
    return Object.keys(object).reduce((acc, key) => {
        return {
            ...acc,
            [key.replace(/([A-Z])/g, function ($1) {
                return "_" + $1.toLowerCase();
            })]: object[key],
        };
    }, {});
}

/**
 * An empty function
 *
 * @returns {null}
 */
export function emptyFunction() {
    return null;
}

/**
 * Get a nested value from an object
 *
 * @param {Object} obj - The object to get the value from
 * @param {string} path - The path to the value to get (e.g., "address.line1")
 * @returns {*} The nested value
 */
export const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, key) => {
        return acc?.[key];
    }, obj);
};
export function convertNumberedObjectToArray(object) {
    return Object.keys(object)
        .filter((key) => {
            return Number.isInteger(parseInt(key));
        })
        .map((key) => {
            return object[key];
        });
}

/**
 * Check if an object is empty
 *
 * @param {Object} object - The object to check
 * @returns {boolean} Whether the object is empty
 */
export function isEmptyObject(object) {
    for (let key in object) {
        if (object.hasOwnProperty(key)) return false;
    }
    return true;
}

/**
 * Check if a parameter is an object
 * TODO: Replace use of this with identically-named method currently in accessibilityUtils.js
 *
 * @param {*} param - The parameter to check
 * @returns {boolean} Whether the parameter is an object
 */
export function isObject(param) {
    return typeof param === "object" && param !== null;
}

/**
 * Removes null, undefined, and empty string values from an object
 *
 * @param {Object} obj the object to remove values from
 * @returns {Object} a new object with the same keys as the input object, but with any null, undefined, or empty string values removed
 */
export function removeNullUndefinedEmptyFromObject(obj) {
    const result = {};
    for (const key in obj) {
        if (
            obj.hasOwnProperty(key) &&
            obj[key] !== null &&
            obj[key] !== undefined &&
            obj[key] !== ""
        ) {
            result[key] = obj[key];
        }
    }
    return result;
}

/**
 * Set a nested value in an object
 *
 * @param {Object} obj - The object to update
 * @param {string} path - The path to the value to update (e.g., "address.line1")
 * @param {*} value - The value to set
 * @returns {Object} The updated object
 */
export const setNestedValue = (obj, path, value) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    const target = keys.reduce((acc, key) => {
        if (!acc[key]) acc[key] = {};
        return acc[key];
    }, obj);
    target[lastKey] = value;
    return { ...obj };
};
