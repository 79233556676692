import { useState } from "react";
import { useHistory } from "react-router";
import sha256 from "crypto-js/sha256";

// Localization
import { useTranslation } from "localization/localization";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";
import { rawNumber } from "utils/regexUtils";

export const useProfileDeleteAccountModal = ({
    profileInfo,
    showNotification,
}) => {
    const { authIds } = profileInfo || {};

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useState
     */

    const [authInfo, setAuthInfo] = useState({});
    const [currentSection, setCurrentSection] = useState("verify");
    const [hasConfirmedDeleteIntent, setHasConfirmedDeleteIntent] =
        useState(false);
    const [loading, setLoading] = useState({
        confirm: false,
        phoneCodeGenerate: false,
        phoneCodeVerify: false,
    });
    const [phoneNumber, setPhoneNumber] = useState({
        baseNumber: "",
        countryCode: "",
        isValid: false,
    });
    const [shouldShowErrorState, setShouldShowErrorState] = useState(false);
    const [verificationMethod, setVerificationMethod] = useState("EMAIL");
    const [verificationCode, setVerificationCode] = useState("");

    /**
     * Constants
     */

    const callbacks = {
        handleMissingEmail: emptyFunction,
        onVerifySuccess: onSocialVerifySuccess,
        showNotification: showNotification,
    };

    const isEmail = authIds?.includes("EMAIL");
    const isEmailCode = authIds?.includes("EMAIL_CODE");
    const isPhone = authIds?.includes("PHONE");
    const isSocial =
        authIds?.includes("GOOGLE") ||
        authIds?.includes("FACEBOOK") ||
        authIds?.includes("APPLE");

    /**
     * End Hooks & Constants
     */

    function handleConfirmationToggle(e) {
        setHasConfirmedDeleteIntent(e.target.checked);
    }

    function handleChangePhoneNumber(isValid, value, countryData) {
        const { dialCode } = countryData;
        setPhoneNumber({
            baseNumber: value,
            countryCode: dialCode,
            isValid,
        });
    }

    function handleClearPhoneNumber() {
        const { dialCode } = arguments[1];
        setPhoneNumber({
            baseNumber: "",
            countryCode: dialCode,
            isValid: false,
        });
    }

    function handleChangeConfirmVerificationCodeInput(value) {
        setVerificationCode(value);
        setShouldShowErrorState(false);
    }

    function handleSubmitConfirmVerifcationCode() {
        setLoading((prev) => {
            return {
                ...prev,
                phoneCodeVerify: true,
            };
        });

        const userPayload = {
            phoneNumber: rawNumber(phoneNumber.baseNumber),
            countryCode: phoneNumber.countryCode,
            verificationCode: verificationCode.join(""),
        };

        SandboxxRestAPI.phoneVerifyPreDelete(
            userPayload,
            onPhoneVerifyPreDeleteSuccess,
            onPhoneVerifyPreDeleteFailure,
            onPhoneVerifyPreDeleteFailure
        );
    }

    function onPhoneVerifyPreDeleteSuccess() {
        setShouldShowErrorState(false);
        setAuthInfo({
            phoneAuth: {
                countryCode: phoneNumber.countryCode,
                phoneNumber: rawNumber(phoneNumber.baseNumber),
            },
        });
        setLoading((prev) => {
            return {
                ...prev,
                phoneCodeVerify: false,
            };
        });
        setCurrentSection("confirm");
    }

    function onPhoneVerifyPreDeleteFailure(err) {
        setShouldShowErrorState(true);
        setLoading((prev) => {
            return {
                ...prev,
                phoneCodeVerify: false,
            };
        });
        showNotification({
            text: err.message,
            type: "warning",
        });
    }

    function resendVerificationCode() {
        const payload = { phoneNumber, verificationType: "sms" };
        return SandboxxRestAPI.resendPhoneVerificationCode(
            payload,
            onResendVerificationCodeSuccess,
            onResendVerificationCodeFailure,
            onResendVerificationCodeFailure
        );
    }

    function onResendVerificationCodeSuccess() {
        showNotification({
            text: t("your_code_has_been_resent", { ns: "common" }),
            type: "success",
        });
    }

    function onResendVerificationCodeFailure({ message }) {
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function handleDeleteAccount() {
        if (hasConfirmedDeleteIntent) {
            setLoading(true);
            AnalyticsLogger.logDeleteAccountConfirm();
            SandboxxRestAPI.deleteAccount(
                onDeleteAccountSuccess,
                authInfo,
                onDeleteAccountFailure,
                onDeleteAccountFailure
            );
        }
    }

    function handleEmailLogin({ email, password }) {
        if (email && password) {
            setCurrentSection("confirm");
            setAuthInfo({
                emailAuth: {
                    email,
                    password: sha256(password).toString(),
                },
            });
            AnalyticsLogger.logDeleteAccountAuthSelected();
        } else {
            showNotification({
                text: t("please_enter_email_password", { ns: "common" }),
                type: "warning",
            });
        }
    }

    function handlePhoneLogin(e) {
        e && e.preventDefault();
        if (
            phoneNumber.countryCode + rawNumber(phoneNumber.baseNumber) ===
            rawNumber(profileInfo.phoneNumber)
        ) {
            setLoading((prev) => {
                return {
                    ...prev,
                    phoneCodeGenerate: true,
                };
            });

            const userPayload = {
                phoneNumber: rawNumber(phoneNumber.baseNumber),
                countryCode: phoneNumber.countryCode,
            };

            SandboxxRestAPI.phoneGeneratePreDelete(
                userPayload,
                onPhoneGeneratePreDeleteSuccess,
                onPhoneGeneratePreDeleteFailure,
                onPhoneGeneratePreDeleteFailure
            );
            AnalyticsLogger.logDeleteAccountAuthSelected();
        } else {
            showNotification({
                text: t("phone_number_does_not_match", { ns: "common" }),
                type: "warning",
            });
        }
    }

    function onPhoneGeneratePreDeleteSuccess() {
        setLoading((prev) => {
            return {
                ...prev,
                phoneCodeGenerate: false,
            };
        });
        setCurrentSection("phoneCodeVerify");
    }

    function onPhoneGeneratePreDeleteFailure({ message }) {
        setLoading((prev) => {
            return {
                ...prev,
                phoneCodeGenerate: false,
            };
        });
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function onDeleteAccountFailure(err) {
        console.error(err);
        AnalyticsLogger.logDeleteAccountError();
        setLoading((prev) => {
            return {
                ...prev,
                confirm: false,
            };
        });
        showNotification({
            text: `${err.message}`,
            type: "warning",
        });
    }

    function onDeleteAccountSuccess() {
        AnalyticsLogger.logDeleteAccountSuccess();
        setLoading((prev) => {
            return {
                ...prev,
                confirm: false,
            };
        });
        showNotification({
            text: t("delete_account.success", { ns: "profile" }),
            type: "success",
        });
        CurrentUser.clearUser();
        history.push("/signin");
    }

    function onSocialVerifySuccess(platformUserData) {
        const { accessToken, userID } = platformUserData;
        const provider = localStorage.getItem("authProvider");
        setCurrentSection("confirm");
        setAuthInfo({
            socialAuth: {
                platform: "WEB",
                provider,
                providerUserId: userID,
                token: accessToken,
            },
        });
    }

    return {
        callbacks,
        hasConfirmedDeleteIntent,
        currentSection,
        handleConfirmationToggle,
        handleDeleteAccount,
        handleEmailLogin,
        handleChangePhoneNumber,
        handleClearPhoneNumber,
        handlePhoneLogin,
        handleChangeConfirmVerificationCodeInput,
        handleSubmitConfirmVerifcationCode,
        isEmail,
        isEmailCode,
        isPhone,
        isSocial,
        loading,
        phoneNumber,
        resendVerificationCode,
        shouldShowErrorState,
        setVerificationMethod,
        setHasConfirmedDeleteIntent,
        setCurrentSection,
        setShouldShowErrorState,
        verificationCode,
        verificationMethod,
    };
};
