import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { Modal } from "components/Modals/Modal";
import { PersonModalAddressFormatter } from "./components/PersonModalAddressFormatter/PersonModalAddressFormatter";
import { PersonModalAddressManual } from "./components/PersonModalAddressManual/PersonModalAddressManual";
import { PersonModalConfirm } from "./components/PersonModalConfirm/PersonModalConfirm";
import { PersonModalForm } from "./components/PersonModalForm/PersonModalForm";
import { PersonModalName } from "components/PersonModal/components/PersonModalName/PersonModalName";

// CSS
import styles from "./person-modal.module.scss";

// Enums
import { PersonModalNavigation } from "./enums/PersonModalNavigation";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const PersonModal = ({
    bases,
    classNames,
    config,
    currentSection,
    exampleAddresses,
    headerText,
    headerTextOptions,
    loading,
    onChange,
    onCloseModal,
    onFormGoBack,
    onSubmit,
    onSubmitAddressFormatter,
    personStaging,
    setCurrentSection,
    setHeaderText,
    shouldShow,
    shouldReverseFullName,
    titles,
    toggleShouldReverseFullName,
}) => {
    function renderCurrentSection() {
        return {
            [PersonModalNavigation.ADDRESS_FORMATTER]: (
                <PersonModalAddressFormatter
                    bases={bases}
                    handleSubmitAddressFormatter={onSubmitAddressFormatter}
                    headerTextOptions={headerTextOptions}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                />
            ),
            [PersonModalNavigation.ADDRESS_MANUAL]: (
                <PersonModalAddressManual
                    exampleAddresses={exampleAddresses}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    personStaging={personStaging}
                    setCurrentSection={setCurrentSection}
                />
            ),
            [PersonModalNavigation.CONFIRM]: (
                <PersonModalConfirm
                    headerTextOptions={headerTextOptions}
                    onSubmit={onSubmit}
                    personStaging={personStaging}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    shouldReverseFullName={shouldReverseFullName}
                />
            ),
            [PersonModalNavigation.FORM]: (
                <PersonModalForm
                    config={config}
                    headerTextOptions={headerTextOptions}
                    loading={loading}
                    onChange={onChange}
                    onFormGoBack={onFormGoBack}
                    onSubmit={onSubmit}
                    personStaging={personStaging}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    shouldReverseFullName={shouldReverseFullName}
                    titles={titles}
                    toggleShouldReverseFullName={toggleShouldReverseFullName}
                />
            ),
            [PersonModalNavigation.NAME]: (
                <PersonModalName
                    headerTextOptions={headerTextOptions}
                    onChange={onChange}
                    personStaging={personStaging}
                    setCurrentSection={setCurrentSection}
                    setHeaderText={setHeaderText}
                    shouldReverseFullName={shouldReverseFullName}
                    titles={titles}
                    toggleShouldReverseFullName={toggleShouldReverseFullName}
                />
            ),
        }[currentSection];
    }

    return (
        <Modal
            className={cx(styles.personModal, classNames.modal)}
            cypressTestId="person-modal"
            headerText={headerText}
            onClose={onCloseModal}
            onHide={onCloseModal}
            show={shouldShow}
        >
            {renderCurrentSection()}
        </Modal>
    );
};

PersonModal.defaultProps = {
    classNames: { modal: "" },
    config: {
        shouldEnableStateFieldToggle: false,
        shouldHideFormAddressFormatterBanner: false,
        shouldHideFormAddressPreview: false,
        shouldHideFormNameToggle: false,
        shouldHideFormTitleField: false,
        shouldShowFormBackButton: false,
        shouldShowFormCountryField: false,
    },
    handleCloseModal: emptyFunction,
    headerTextOptions: {
        addressFormatter: "",
        addressManual: "",
        confirm: "",
        form: "",
        name: "",
    },
    onChange: emptyFunction,
    onSubmit: emptyFunction,
    onSubmitAddressFormatter: emptyFunction,
    setHeaderText: emptyFunction,
};

PersonModal.propTypes = {
    bases: PropTypes.array,
    classNames: PropTypes.object,
    config: PropTypes.shape({
        shouldEnableStateFieldToggle: PropTypes.bool,
        shouldHideFormAddressFormatterBanner: PropTypes.bool,
        shouldHideFormAddressPreview: PropTypes.bool,
        shouldHideFormNameToggle: PropTypes.bool,
        shouldHideFormTitleField: PropTypes.bool,
        shouldShowFormBackButton: PropTypes.bool,
        shouldShowFormCountryField: PropTypes.bool,
    }),
    currentSection: PropTypes.string,
    exampleAddresses: PropTypes.array,
    headerText: PropTypes.string,
    headerTextOptions: PropTypes.shape({
        addressFormatter: PropTypes.string,
        addressManual: PropTypes.string,
        confirm: PropTypes.string,
        form: PropTypes.string,
        name: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onFormGoBack: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onSubmitAddressFormatter: PropTypes.func,
    personStaging: PropTypes.object.isRequired,
    setHeaderText: PropTypes.func,
    shouldReverseFullName: PropTypes.bool,
    shouldShow: PropTypes.bool.isRequired,
    titles: PropTypes.array,
};
