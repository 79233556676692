import React from "react";
import cx from "classnames";

// Components
import { AddressCard } from "components/AddressCard/AddressCard";
import { Banner } from "components/Banner/Banner";
import { ButtonInvisible, ButtonPrimary } from "components/Buttons/Buttons";
import { DropdownState } from "components/Dropdowns/DropdownState/DropdownState";
import { MaterialIcon } from "components/MaterialIcon/MaterialIcon";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";
import { TextField } from "components/Inputs/TextField/TextField";

// CSS
import styles from "./letters-sent-letter-resolution-modal.module.scss";

// Hooks
import { useLettersSentLetterResolutionModal } from "./hooks/useLettersSentLetterResolutionModal";

// Platforms
import { Intercom } from "platforms/intercom";

export const LettersSentLetterResolutionModal = ({
    letter,
    onClose,
    onHide,
    resolutionModalSize,
    setResolutionModalSize,
    shouldShowResolutionModal,
}) => {
    /**
     * Custom Hooks
     */

    const {
        exampleAddresses,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidRecipient,
        recipientStaging,
    } = useLettersSentLetterResolutionModal({ letter, setResolutionModalSize });

    /**
     * Constants
     */

    const { city, line1, line2, name, state, zipcode } = recipientStaging;

    /**
     * End Hooks
     */

    function renderExampleAddresses() {
        if (exampleAddresses && exampleAddresses.length > 0) {
            return (
                <div className={styles.exampleAddresses}>
                    <Text classes={styles.title} isBold>
                        Example Addresses
                    </Text>
                    <Text classes={styles.subtitle}>
                        The addresses below show examples of what an address
                        should look like at the zip code you sent your letter
                        to.
                    </Text>
                    <div className={styles.addresses}>
                        {exampleAddresses?.map((address, i) => (
                            <AddressCard
                                className={styles.addressCard}
                                config={{ shouldHideStamp: true }}
                                contact={{
                                    address,
                                }}
                                key={i}
                                name={letter.recipient.name}
                            />
                        ))}
                    </div>
                </div>
            );
        }
    }

    return (
        <Modal
            bodyClasses={styles.modalBody}
            className={styles.lettersSentLetterResolutionModal}
            headerSubText="There may be something wrong with the address you provided. Please update and save the recipient's address below or contact Customer Happiness for help."
            headerText={
                <>
                    <MaterialIcon
                        className={styles.icon}
                        color="var(--mission-orange-100)"
                        name="warning"
                    />
                    <Text>Letter On Hold</Text>
                </>
            }
            onClose={onClose}
            onHide={onHide}
            show={shouldShowResolutionModal}
            size={resolutionModalSize}
        >
            <form
                className={cx(styles.form, {
                    [styles["form--noExampleAddresses"]]:
                        !exampleAddresses || exampleAddresses?.length === 0,
                })}
                onSubmit={handleSubmit}
            >
                <TextField
                    className={cx(styles.input, styles["input--name"])}
                    cypressTestId="letters-sent-resolution-modal-form-name"
                    disabled
                    id="name"
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={name}
                />
                <TextField
                    className={cx(styles.input, styles["input--line1"])}
                    cypressTestId="letters-sent-resolution-modal-form-line-1"
                    id="line1"
                    label="Address Line 1"
                    name="line1"
                    onChange={handleChange}
                    required
                    value={line1}
                />
                <TextField
                    className={cx(styles.input, styles["input--line2"])}
                    cypressTestId="letters-sent-resolution-modal-form-line-2"
                    id="line2"
                    label="Address Line 2"
                    name="line2"
                    onChange={handleChange}
                    value={line2}
                />
                <TextField
                    className={cx(styles.input, styles["input--city"])}
                    cypressTestId="letters-sent-resolution-modal-form-city"
                    id="city"
                    label="City"
                    name="city"
                    onChange={handleChange}
                    required
                    value={city}
                />
                <DropdownState
                    className={cx(styles.input, styles["input--state"])}
                    cypressTestId="letters-sent-resolution-modal-form-state"
                    name="state"
                    onChange={handleChange}
                    value={state}
                />
                <TextField
                    className={cx(styles.input, styles["input--zipcode"])}
                    cypressTestId="letters-sent-resolution-modal-form-zipcode"
                    id="zipcode"
                    label="Zip Code"
                    name="zipcode"
                    onChange={handleChange}
                    required
                    value={zipcode}
                />
                <Banner
                    classes={styles.banner}
                    config={{ shouldShowChevron: true }}
                    onClick={() =>
                        Intercom.showNewMessage(
                            'Hello Sandboxx, I understand my letter is "On Hold". Do you have any more information?'
                        )
                    }
                >
                    <div className={styles.content}>
                        <Text isBold>Need Help?</Text>
                        <Text>
                            Get in touch with our Customer Happiness team.
                        </Text>
                    </div>
                </Banner>
                <div className={styles.buttons}>
                    <ButtonPrimary
                        onClick={handleSubmit}
                        isDisabled={isSubmitting || !isValidRecipient}
                        type="submit"
                    >
                        <Text>{isSubmitting ? "Saving..." : "Save"}</Text>
                    </ButtonPrimary>
                    <ButtonInvisible onClick={onClose} type="button">
                        <Text>Cancel</Text>
                    </ButtonInvisible>
                </div>
            </form>
            {renderExampleAddresses()}
        </Modal>
    );
};
