import { useState } from "react";
import { setNestedValue } from "utils/objectUtils";

/**
 * Custom Hook for managing nested form state
 * - Handles nested properties (e.g., "address.city")
 * - Automatically deep copies the initial state to prevent direct mutation
 *
 * @param {Object} initialState - The initial state of the form
 * @returns {Object} An object containing the form state and a handleChange function
 */
export const useNestedFormState = (initialState) => {
    const [formState, setFormState] = useState(
        JSON.parse(JSON.stringify(initialState))
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => {
            const newState = JSON.parse(JSON.stringify(prev));
            return setNestedValue(newState, name, value);
        });
    };

    return {
        formState,
        handleChange,
        setFormState,
    };
};
