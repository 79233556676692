import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Components
import { Banner } from "components/Banner/Banner";
import { Text } from "components/Text/Text";

// CSS
import styles from "./person-modal-address-manual-example-address.module.scss";

export const PersonModalAddressManualExampleAddress = ({
    className,
    exampleAddresses,
    personStaging,
}) => {
    const { zipcode: requestedZipcode } = personStaging.address;
    if (
        requestedZipcode.length === 5 &&
        exampleAddresses &&
        exampleAddresses[0]
    ) {
        const { line1, line2, city, state, zipcode } = exampleAddresses[0];
        return (
            <Banner
                classes={cx(
                    styles.contactModalAddressManualExampleAddress,
                    className
                )}
            >
                <Text isBold>Need help with your recipient's address?</Text>
                <Text>
                    Here's an example for the {requestedZipcode} zip code:
                </Text>
                <div className={styles.address}>
                    <Text>{personStaging.fullName}</Text>
                    <Text>{line1}</Text>
                    <Text>{line2}</Text>
                    <Text>
                        {city}, {state} {zipcode}
                    </Text>
                </div>
            </Banner>
        );
    }
    return null;
};

PersonModalAddressManualExampleAddress.propTypes = {
    className: PropTypes.string,
    exampleAddresses: PropTypes.array,
    personStaging: PropTypes.object.isRequired,
};
