import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./letter-preview-description.module.scss";

// Enums
import { SentLetterVisualStatus } from "enums/letters/SentLetterVisualStatus";

// Localization
import { useTranslation } from "localization/localization";

// Models
import { SentLetter } from "models/letters/SentLetter";
import { ShippingCarrier } from "enums/letters/ShippingCarrier";

export const LetterPreviewDescription = ({ className, isLoading, letter }) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    // We can only provide tracking and delivery confirmation for Fedex
    const isFedex = letter?.shippingCarrier === ShippingCarrier.FEDEX;
    const visualStatus = letter?.visualStatus;

    if (isLoading) {
        return (
            <div
                className={cx(
                    styles.letterPreviewDescription,
                    styles["letterPreviewDescription--loading"],
                    className
                )}
            >
                <Skeleton className={cx(styles.skeleton, "skeleton")} />
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.DELIVERED) {
        const text = letter.delivery_date
            ? t("letter_preview.delivered_on", {
                  date: letter.deliveryDatePretty,
                  ns: "components",
              })
            : t("letter_preview.delivered", { ns: "components" });
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>{text}</Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.IN_REVIEW) {
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>
                    {t("letter_preview.delivery_review", { ns: "components" })}
                </Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.RECEIVED) {
        const text = isFedex
            ? t("letter_preview.shipping_soon_tracking", { ns: "components" })
            : t("letter_preview.shipping_soon_no_tracking", {
                  ns: "components",
              });
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>{text}</Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.ON_HOLD) {
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>
                    {t("letter_preview.on_hold_message", { ns: "components" })}
                </Text>
            </div>
        );
    }
    if (visualStatus === SentLetterVisualStatus.SHIPPED) {
        const text =
            isFedex && letter.expected_delivery_date
                ? t("letter_preview.expected_delivery_date", {
                      date: letter.expectedDeliveryDatePretty,
                      ns: "components",
                  })
                : t("letter_preview.expected_delivery_no_tracking", {
                      ns: "components",
                  });
        return (
            <div className={cx(styles.letterPreviewDescription, className)}>
                <Text>{text}</Text>
            </div>
        );
    }
    return (
        <div className={cx(styles.letterPreviewDescription, className)}>
            <Text>
                {t("letter_preview.letter_being_processed", {
                    ns: "components",
                })}
            </Text>
        </div>
    );
};

LetterPreviewDescription.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    letter: PropTypes.instanceOf(SentLetter),
};
