import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import {
    ButtonInvisible,
    ButtonPrimary,
    ButtonSecondary,
} from "components/Buttons/Buttons";
import { Text } from "components/Text/Text";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { isEmptyObject } from "utils/objectUtils";

// Constants
import { NavigationEnum } from "../../constants/constants";
const { REVIEW, SELECT_PAYMENT, SELECT_DESIGN } = NavigationEnum;

export const WalletManagePurchaseCardModalSelectAmount = ({
    giftCard,
    recentPaymentMethod,
    setCurrentSection,
    selectGiftCardAmount,
}) => {
    const { selected } = giftCard;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useState
     */

    const [selectedAmount, setSelectAmount] = useState(null);

    /**
     * End Hooks
     */

    function handleAmountOnClick(amount) {
        setSelectAmount(amount);
    }

    function handleGoBack() {
        setCurrentSection(SELECT_DESIGN);
    }

    function handleSubmit() {
        const { details, method } = recentPaymentMethod;
        selectGiftCardAmount(selectedAmount);
        if (!isEmptyObject(details) && method) {
            setCurrentSection(REVIEW);
        } else {
            setCurrentSection(SELECT_PAYMENT);
        }
    }

    function renderAmounts() {
        const { denominations, feeTotals } = selected;
        return denominations.map((base, i) => {
            const isFilled = selectedAmount?.base === base;
            const fee = feeTotals[i];
            const total = base + fee;
            const amount = { base, fee, total };
            return (
                <ButtonSecondary
                    classes="letters-compose-add-ons__select-amount__amounts__amount"
                    cypressTestId={`select-amount-${base}`}
                    isFilled={isFilled}
                    key={i}
                    onClick={() => handleAmountOnClick(amount)}
                >
                    <Text>{`$${base}`}</Text>
                </ButtonSecondary>
            );
        });
    }

    function renderDesign() {
        const { id, photoUrlFull } = selected;
        const style = { backgroundImage: `url(${photoUrlFull})` };
        return <div className="gift-card" key={id} style={style} />;
    }

    return (
        <div data-cy="wallet-purchase-card-modal-select-amount">
            <div className="letters-compose-add-ons__select-amount__design">
                {renderDesign()}
            </div>
            <div className="letters-compose-add-ons__select-amount__amounts">
                {renderAmounts()}
            </div>
            <div
                className="button__container"
                style={{ justifyContent: "space-between" }}
            >
                <ButtonInvisible onClick={handleGoBack}>
                    <Text>{t("back", { ns: "common" })}</Text>
                </ButtonInvisible>
                <ButtonPrimary
                    cypressTestId="wallet-purchase-modal-select-amount-next-button"
                    isDisabled={!selectedAmount}
                    onClick={handleSubmit}
                >
                    <Text>{t("next", { ns: "common" })}</Text>
                </ButtonPrimary>
            </div>
        </div>
    );
};

WalletManagePurchaseCardModalSelectAmount.propTypes = {
    giftCard: PropTypes.object.isRequired,
    handleClosePurchaseCardModal: PropTypes.func.isRequired,
    recentPaymentMethod: PropTypes.object.isRequired,
    setCurrentSection: PropTypes.func.isRequired,
    selectGiftCardAmount: PropTypes.func.isRequired,
};
