import React from "react";
import cx from "classnames";
import { DateTime } from "luxon";

// Components
import { Avatar } from "../Avatar/Avatar";

// Utils
import { emptyFunction } from "utils/objectUtils";
import { handleKeyDown } from "utils/eventUtils";
import { titleCase } from "../../utils/textUtils";

export const UserListItem = (props) => {
    const {
        avatarClassNames,
        avatarWithoutImageColor,
        isClickable,
        containerClasses,
        config,
        shouldDisplayShipDate,
        icon,
        isComposingLetter,
        handleUserSelect,
        onClick,
        selectedUserListItem,
        user,
        // "type" is used here to create variations of UserListItem, currently the only types are default and rounded
        type,
    } = props;
    const { includeTo } = config;

    function formatShipDate(shipDate) {
        const shipDateArray = shipDate.split("-");
        shipDateArray.forEach((string, i, arr) =>
            string.length < 2 ? (arr[i] = `0${string}`) : null
        );
        return (
            shipDateArray[0] + "-" + shipDateArray[1] + "-" + shipDateArray[2]
        );
    }

    function onUserSelect() {
        const selectedUser = { suggestion: user };
        handleUserSelect(selectedUser);
        onClick && onClick();
    }

    function renderIcon() {
        return (
            icon?.src &&
            icon?.shouldShow(user) && (
                <div className="user-list__item__icon">
                    <img alt="User icon" src={icon.src} />
                </div>
            )
        );
    }

    function renderSecondaryInfo(address, isSelected) {
        if (shouldDisplayShipDate && user.basicTrainingShipDate) {
            const shipDate = user.basicTrainingShipDate;
            formatShipDate(shipDate);
            const secondaryInfoText = DateTime.fromISO(
                formatShipDate(shipDate)
            ).toLocaleString(DateTime.DATE_MED);
            return (
                secondaryInfoText && (
                    <span
                        className={cx(`user-list__item__info__secondary`, {
                            [`user-list__item__info__secondary--selected`]:
                                isSelected,
                        })}
                    >
                        {secondaryInfoText}
                    </span>
                )
            );
        } else if (address) {
            let secondaryInfoText = null;
            const { city, line1, state } = address;
            const cityState =
                city && state && `${titleCase(city)}, ${state.toUpperCase()}`;

            // If user does not have populated address object...
            if (!line1 && isComposingLetter) {
                secondaryInfoText =
                    "This user does not have an address. Please add one on the review screen.";
            } else {
                secondaryInfoText = cityState;
            }
            return (
                secondaryInfoText && (
                    <span
                        className={cx(`user-list__item__info__secondary`, {
                            [`user-list__item__info__secondary--selected`]:
                                isSelected,
                            [`user-list__item__info__secondary--rounded`]:
                                type === "rounded",
                        })}
                    >
                        {secondaryInfoText}
                    </span>
                )
            );
        }
    }

    const to = includeTo ? "To: " : "";
    const isSelected =
        selectedUserListItem.id && selectedUserListItem.id === user.id
            ? "selected"
            : null;

    const secondaryInfo = renderSecondaryInfo(user.address, isSelected);
    const multiLineClass = secondaryInfo && "multi-line";

    return (user.firstName && user.lastName) || user.fullName ? (
        <div
            className={cx(
                `letters-compose-message-contacts-contact-container user-list__item`,
                containerClasses,
                {
                    [`user-list__item--selected`]: isSelected,
                    [`user-list__item--clickable`]: isClickable,
                    [`user-list__item--rounded`]: type === "rounded",
                }
            )}
            data-cy={`user-list-item-user-${user.firstName}-${user.lastName}`}
            id={`user-list-item-user-${user.abContactId}`}
            onClick={isClickable ? onUserSelect : null}
            onKeyDown={
                isClickable
                    ? (e) => {
                          handleKeyDown(e, onUserSelect);
                      }
                    : null
            }
            tabIndex={isClickable ? "0" : null}
        >
            <Avatar
                additionalClasses={`letters-compose-message-contacts-contact-avatar user-list__item__avatar ${multiLineClass} ${avatarClassNames}`}
                user={user}
                withoutImageColor={avatarWithoutImageColor}
            />
            <div
                className={`letters-compose-message-contacts-contact-info user-list__item__info ${multiLineClass}`}
            >
                <span
                    className={cx(`user-list__item__info__primary`, {
                        [`user-list__item__info__primary--selected`]:
                            isSelected,
                        [`user-list__item__info__primary--rounded`]:
                            type === "rounded",
                    })}
                    data-cy="user-list-item-info-primary"
                >
                    {to}
                    {user.fullName}
                </span>
                {secondaryInfo}
            </div>
            {renderIcon()}
        </div>
    ) : null;
};

UserListItem.defaultProps = {
    config: { includeTo: false },
    shouldDisplayShipDate: false,
    handleUserSelect: emptyFunction,
    selectedUserListItem: {},
};
