import React from "react";

// Components
import { AddPaymentOption } from "components/PaymentOption/AddPaymentOption";
import { Divider } from "components/Divider/Divider";
import { SavedPaymentOptions } from "components/PaymentOption/SavedPaymentOptions";
import { Text } from "components/Text/Text";

// Constants
import {
    braintreeFormText,
    braintreeFormClassNames,
} from "./constants/constants";

// CSS
import styles from "./wallet-gift-card-purchase-billing-details.module.scss";

// Enums
import { NavigationEnum } from "../../enums/NavigationEnum";
import { LayoutEnum as SavedPaymentOptionsLayoutEnum } from "components/PaymentOption/constants/constants";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { emptyFunction } from "utils/objectUtils";

export const WalletGiftCardsPurchaseBillingDetails = (props) => {
    const {
        loading,
        paymentMethod,
        savedCards,
        setCurrentSection,
        setLoading,
        setOrderProgress,
        setPaymentMethod,
        showNotification,
    } = props;

    function handleGoBack() {
        setOrderProgress((prev) => ({ ...prev, sender: false }));
        setCurrentSection(NavigationEnum.SENDER);
    }

    function handlePaymentMethodSelect(paymentMethod) {
        AnalyticsLogger.logGiftCardOrderReview();
        setPaymentMethod(paymentMethod);
        setOrderProgress((prev) => ({ ...prev, paymentMethod: true }));
        setCurrentSection(NavigationEnum.REVIEW);
    }

    return (
        <div className={styles["wallet-gift-cards-purchase-billing-details"]}>
            <div className={styles.header}>
                <Text type="h2">Billing Details</Text>
            </div>
            <div className={styles["saved-payment-methods"]}>
                <div className={styles.header}>
                    <Text isBold type="h4">
                        Saved Credit Cards
                    </Text>
                </div>
                <SavedPaymentOptions
                    changeCurrentSection={emptyFunction}
                    config={{ shouldHideHeader: true }}
                    handleSavedCardSelect={handlePaymentMethodSelect}
                    layout={SavedPaymentOptionsLayoutEnum.CARD}
                    savedCards={savedCards}
                    selectedCard={paymentMethod}
                />
            </div>
            <div className={styles.divider}>
                <Divider text="OR" />
            </div>
            <div className={styles["add-payment-method"]}>
                <div className={styles.header}>
                    <Text isBold type="h4">
                        Pay With Card
                    </Text>
                </div>
                <AddPaymentOption
                    classNames={braintreeFormClassNames}
                    handleGoBack={handleGoBack}
                    loading={loading}
                    onSuccess={handlePaymentMethodSelect}
                    setLoading={setLoading}
                    showNotification={showNotification}
                    text={braintreeFormText}
                />
            </div>
        </div>
    );
};
