import { useEffect, useState } from "react";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Utils
import { generateCreditCardIconSrc } from "utils/purchaseUtils";

// Validation
import { LettersValidation } from "validation/lettersValidation";

// Enums
import { LettersComposeNavigation } from "../../enums/enums";

export const useLettersComposeReview = (props) => {
    const {
        changeComposeCurrentSection,
        credits,
        handleInitializeSaveDraft,
        message,
        recipient,
        selectedCard,
        setHeaderBarContent,
        showNotification,
        supportSquad,
        toggleContactModal,
    } = props;

    /**
     * useState
     */

    const [editStatuses, setEditStatuses] = useState({
        message: false,
        photo: false,
    });
    const [isEditing, setIsEditing] = useState(false);

    /**
     * Constants
     */

    const { connected } = recipient;
    const { brand, last4 } = selectedCard?.card || {};
    const creditCardIconSrc = generateCreditCardIconSrc(brand);
    const hasCredits = LettersValidation.validateCredits({
        credits,
        recipient,
        supportSquad,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        setHeaderBarContent({
            onBackClick: handleGoBack,
            text: "Review Your Order",
            textSecondary: "Safe & Secure Checkout",
        });
        AnalyticsLogger.logLetterComposeReviewViewed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks & Constants
     */

    function handleGoBack() {
        changeComposeCurrentSection(LettersComposeNavigation.COMPOSE);
    }

    function handleSaveMessage() {
        handleInitializeSaveDraft(true);
        toggleEditPanel("message");
    }

    function toggleEditPanel(key) {
        const isValidInput = validateInput(key);
        const isEditingMessage =
            key === "message" && isEditing && editStatuses.message;
        const willEditMessage =
            key === "message" && !isEditing && !editStatuses.message;
        if (isValidInput) {
            if (isEditingMessage) {
                AnalyticsLogger.logEditLetterSave();
            }
            if (willEditMessage) {
                AnalyticsLogger.logEditLetter();
            }
            setEditStatuses((prev) => ({
                ...prev,
                [key]: !prev[key],
            }));
            setIsEditing((prev) => !prev);
        }
    }

    function handleToggleContactModal() {
        toggleContactModal({
            contactToEdit: recipient,
            isWritingLetter: true,
            shouldShow: true,
            targetScreen: PersonModalNavigation.FORM,
            type: "edit",
        });
    }

    function validateInput(key) {
        if (key === "message") {
            if (message.content) {
                return true;
            } else {
                showNotification({
                    text: "Please include a message with your letter.",
                    type: "warning",
                });
                return false;
            }
        }
        return true;
    }

    return {
        brand,
        connected,
        creditCardIconSrc,
        editStatuses,
        handleSaveMessage,
        handleToggleContactModal,
        hasCredits,
        last4,
        toggleEditPanel,
    };
};
