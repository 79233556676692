import React, { useContext, useEffect, useState } from "react";
import { Modal } from "components/Modals/Modal";

// Components
import { LettersComposeAddOnsConfirmation } from "./LettersComposeAddOnsConfirmation";
import { LettersComposeAddOnsGiftCardSelectAmount } from "./LettersComposeAddOnsGiftCardSelectAmount";
import { LettersComposeAddOnsGiftCardSelectDesign } from "./LettersComposeAddOnsGiftCardSelectDesign";
import { LettersComposeAddOnsSelectPayment } from "./LettersComposeAddOnsSelectPayment";

// Context
import { PurchaseContext } from "../../../../../context/purchase";

// CSS
import "../../../../../css/letters/scenes/GiftCard/letters-compose-add-ons.scss";

// Localization
import { useTranslation } from "localization/localization";

// Utils
import { isEmptyObject } from "utils/objectUtils";
import { LettersComposeAddOnsSelectItems } from "./LettersComposeAddOnsSelectItems";

// Enums
import { AddOnsNavigation } from "../enums/enums";

export const LettersComposeAddOnsModal = (props) => {
    const {
        addOns,
        giftCard,
        handleAddOnsChangeSection,
        handleAddOnsModalToggle,
        handleRemoveAddOn,
        handleSelectAddOn,
        handleLetterSend,
        handleGiftCardClear,
        handleSelectGiftCardAmount,
        handleGiftCardSelectOption,
        navigation,
        recentPaymentMethod,
        setDisplay,
        setGiftCard,
        showAddOnsModal,
        showNotification,
    } = props;

    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { fetchSavedCards, savedCards, selectedCard, setSelectedCard } =
        useContext(PurchaseContext);

    /**
     * useState
     */

    const [loading, setLoading] = useState({
        braintreeNewCard: false,
        braintreeSavedCard: false,
    });
    const [newCard, setNewCard] = useState({
        id: null,
        name: null,
        saveCard: true,
        token: null,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        if (recentPaymentMethod) {
            const { details, method } = recentPaymentMethod;
            if (!isEmptyObject(details) && method) {
                setSelectedCard((prev) => ({
                    ...prev,
                    card: details,
                    isSavedCard: true,
                }));
            }
        }
    }, [recentPaymentMethod, setSelectedCard]);

    /**
     * End Hooks
     */

    /**
     * * Hides LettersComposeAddOnsModal
     * * Clears the selected gift card in state if no design or amount base is present
     *
     * @param   {bool}  confirmationOverride  If true, the user can close the gift card modal from the confirmation screen
     * @param   {bool}  shouldNotClear  Override that prevents the select gift card from being cleared when the modal is closed
     *
     * TODO: Move confirmationOverride into config params
     */
    function handleCloseModal(
        confirmationOverride = false,
        { shouldNotClear = false } = {}
    ) {
        if (navigation.addOnsModal !== "confirmation" || confirmationOverride) {
            setDisplay((prev) => ({
                ...prev,
                showAddOnsModal: false,
            }));
            setTimeout(() => {
                handleAddOnsChangeSection(AddOnsNavigation.SELECT_ITEMS);
                if (
                    !shouldNotClear &&
                    (isEmptyObject(giftCard.selected) || !giftCard.amount.base)
                ) {
                    setGiftCard((prev) => ({
                        ...prev,
                        selected: {},
                    }));
                }
            }, 1000);
        }
    }

    function getHeaderText(section) {
        switch (section) {
            case AddOnsNavigation.SELECT_ITEMS:
                return t("select_a_letter_add_on", { ns: "letters" });
            case AddOnsNavigation.SELECT_DESIGN:
                return t("select_a_design", { ns: "letters" });
            case AddOnsNavigation.SELECT_AMOUNT:
                return t("select_an_amount", { ns: "letters" });
            case AddOnsNavigation.SELECT_PAYMENT:
                return t("enter_gift_card_payment_information", {
                    ns: "letters",
                });
            default:
                return "";
        }
    }

    function renderCurrentSection() {
        return {
            [AddOnsNavigation.SELECT_ITEMS]: (
                <LettersComposeAddOnsSelectItems
                    addOns={addOns}
                    changeCurrentSection={handleAddOnsChangeSection}
                    giftCard={giftCard}
                    handleRemoveAddOn={handleRemoveAddOn}
                    handleSelectAddOn={handleSelectAddOn}
                    handleGiftCardClear={handleGiftCardClear}
                    handleAddOnsModalToggle={handleAddOnsModalToggle}
                />
            ),
            [AddOnsNavigation.SELECT_DESIGN]: (
                <LettersComposeAddOnsGiftCardSelectDesign
                    changeCurrentSection={handleAddOnsChangeSection}
                    giftCard={giftCard}
                    handleCloseModal={handleCloseModal}
                    handleGiftCardSelectOption={handleGiftCardSelectOption}
                />
            ),
            [AddOnsNavigation.SELECT_AMOUNT]: (
                <LettersComposeAddOnsGiftCardSelectAmount
                    giftCard={giftCard}
                    changeCurrentSection={handleAddOnsChangeSection}
                    handleCloseModal={handleCloseModal}
                    handleSelectGiftCardAmount={handleSelectGiftCardAmount}
                />
            ),
            [AddOnsNavigation.SELECT_PAYMENT]: (
                <LettersComposeAddOnsSelectPayment
                    changeCurrentSection={handleAddOnsChangeSection}
                    fetchSavedCards={fetchSavedCards}
                    handleCloseModal={handleCloseModal}
                    loading={loading}
                    newCard={newCard}
                    savedCards={savedCards}
                    selectedCard={selectedCard}
                    setLoading={setLoading}
                    setNewCard={setNewCard}
                    setSelectedCard={setSelectedCard}
                    showNotification={showNotification}
                />
            ),
            [AddOnsNavigation.CONFIRMATION]: (
                <LettersComposeAddOnsConfirmation
                    handleCloseModal={handleCloseModal}
                    handleLetterSend={handleLetterSend}
                />
            ),
        }[navigation.addOnsModal];
    }

    return (
        <Modal
            className="letters-compose-add-ons__modal"
            cypressTestId="letters-compose-add-ons-modal"
            headerText={getHeaderText(navigation.addOnsModal)}
            show={showAddOnsModal}
            onHide={handleCloseModal}
        >
            {renderCurrentSection()}
        </Modal>
    );
};
