import { useContext, useEffect, useState } from "react";

// API
import { SandboxxRestAPI } from "utils/sandboxx";

// Context
import { NotificationBarContext } from "context/notificationBar";

// Enums
import { PersonModalNavigation } from "components/PersonModal/enums/PersonModalNavigation";

// Hooks
import { useNestedFormState } from "hooks/forms/useNestedFormState";

// Localization
import { useTranslation } from "localization/localization";

export const useLettersComposeSenderModal = ({
    fetchAddOns,
    sender,
    setSender,
    setShouldShowSenderModal,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * Constants
     */

    const headerTextOptions = {
        addressFormatter: "",
        addressManual: "",
        confirm: "",
        form: t("edit_your_address", { ns: "common" }),
        name: "",
    };

    /**
     * useState
     */

    const [currentSection, setCurrentSection] = useState(
        PersonModalNavigation.FORM
    );
    const [headerText, setHeaderText] = useState(headerTextOptions.form);
    const {
        formState: senderStaging,
        handleChange,
        setFormState: setSenderStaging,
    } = useNestedFormState(sender);
    const [loading, setLoading] = useState({
        form: false,
    });

    /**
     * useEffect
     */

    useEffect(() => {
        if (sender) {
            setSenderStaging(sender);
        }
    }, [sender, setSenderStaging]);

    /**
     * End Hooks
     */

    function handleSubmit(e) {
        e.preventDefault();
        setLoading((prev) => ({
            ...prev,
            form: true,
        }));
        SandboxxRestAPI.updateUserAddress(
            senderStaging,
            onUpdateUserAddressSuccess,
            onUpdateUserAddressFailure,
            onUpdateUserAddressFailure
        );
    }

    function onUpdateUserAddressFailure({ message }) {
        setLoading((prev) => ({
            ...prev,
            updateUserAddress: false,
        }));
        showNotification({
            text: message,
            type: "warning",
        });
    }

    function onUpdateUserAddressSuccess(res) {
        setLoading((prev) => ({
            ...prev,
            form: false,
        }));
        fetchAddOns();
        setSender({
            address: res.address,
            firstName: res.firstName,
            fullName: res.fullName,
            lastName: res.lastName,
        });
        setShouldShowSenderModal(false);
    }

    return {
        currentSection,
        handleChange,
        handleSubmit,
        headerText,
        headerTextOptions,
        loading,
        senderStaging,
        setCurrentSection,
        setHeaderText,
        setLoading,
    };
};
