import { useEffect, useMemo, useState } from "react";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

// Platforms
import { SandboxxRestAPI } from "utils/sandboxx";

// Utils
import { isEmptyObject } from "utils/objectUtils";

export const useGiftCard = (recipient, setLoading) => {
    /**
     * useState
     *
     * giftCard
     * -----------------------------------
     * - `amount`: Object containing the `base`, `fee`, and `total` dollar
     *      values associated with the card
     * - `braintree`: Contains Braintree information to be used when
     *      purchasing the Gift Card
     * - `hasFetchedPhotos`: Boolean that indicates whether Gift Card photos
     *      have been fetched or not
     * - `options`: Array containing Gift Card options
     * - `purchased`: Object containing information about a purchased Gift Card
     * - `selected`: Object containing information about a selected Gift Card
     *      prior to purchase
     * - `triggerLetterSendSubmit`: Boolean that when set to true will cause the
     *      current letter to be send
     */

    const [giftCard, setGiftCard] = useState({
        amount: {
            base: 0,
            fee: 0,
            total: 0,
        },
        braintree: {},
        hasFetchedPhotos: false,
        options: [],
        purchased: {},
        selected: {},
        triggerLetterSendSubmit: false,
    });

    /**
     * useMemo
     */

    const hasPurchasedGiftCard = useMemo(
        () => !isEmptyObject(giftCard.purchased),
        [giftCard.purchased]
    );

    const hasSelectedGiftCard = useMemo(
        () => !isEmptyObject(giftCard.selected),
        [giftCard.selected]
    );

    /**
     * useEffect
     */

    /**
     * Fetch Gift Card photos after options have been successfully fetched
     */
    useEffect(() => {
        const { hasFetchedPhotos, options } = giftCard;
        if (!!options.length && !hasFetchedPhotos) {
            fetchGiftCardPhotos();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giftCard.options]);

    /**
     * End Hooks
     */

    /**
     * Resets Gift Card in state to initial values
     */
    function clearGiftCard() {
        setGiftCard((prev) => ({
            ...prev,
            amount: { base: 0, fee: 0, total: 0 },
            braintree: {},
            hasFetchedPhotos: false,
            purchased: {},
            selected: {},
        }));
    }

    function fetchGiftCardOptions() {
        const { zipcode } = recipient.address;
        setLoading((prevLoading) => ({
            ...prevLoading,
            giftCardOptions: true,
        }));
        return SandboxxRestAPI.getGiftCardOptions(
            { zipCode: zipcode },
            onFetchGiftCardOptionsSuccess,
            onFetchGiftCardOptionsFailure,
            onFetchGiftCardOptionsFailure
        );
    }

    function handleGiftCardClear() {
        clearGiftCard();
        AnalyticsLogger.logRemoveGiftCardFromCart(giftCard);
    }

    function handleSelectGiftCardAmount(selectedAmount) {
        selectGiftCardAmount(selectedAmount);
        AnalyticsLogger.logAddGiftCardToCart(giftCard, selectedAmount.base);
        AnalyticsLogger.logGiftCardEnd();
    }

    function handleGiftCardSelectOption(selected) {
        setGiftCard((prevGiftCard) => ({
            ...prevGiftCard,
            selected,
        }));
    }

    /**
     * Fetches and updates Gift Card options in state with photo data
     */
    function fetchGiftCardPhotos() {
        Promise.all(
            giftCard.options.map((option) => {
                return SandboxxRestAPI.getImageCloudfront(
                    option.photo_url,
                    (photoUrl) => photoUrl
                );
            })
        ).then((photoUrls) => {
            setGiftCard((prev) => ({
                ...prev,
                hasFetchedPhotos: true,
                options: prev.options.map((option, i) => ({
                    ...option,
                    photoUrlFull: photoUrls[i],
                })),
            }));
        });
    }

    function onFetchGiftCardOptionsFailure(err) {
        setLoading((prev) => ({
            ...prev,
            giftCardOptions: false,
        }));
    }

    function onFetchGiftCardOptionsSuccess(options) {
        setLoading((prev) => ({
            ...prev,
            giftCardOptions: false,
        }));
        setGiftCard((prev) => ({
            ...prev,
            options,
        }));
    }

    function selectGiftCardAmount(selectedAmount) {
        const totalCalculated = selectedAmount.base + selectedAmount.fee;
        setGiftCard((prev) => ({
            ...prev,
            amount: {
                base: selectedAmount.base,
                fee: selectedAmount.fee,
                total: totalCalculated,
            },
        }));
    }

    return {
        clearGiftCard,
        fetchGiftCardOptions,
        handleGiftCardClear,
        handleGiftCardSelectOption,
        handleSelectGiftCardAmount,
        hasPurchasedGiftCard,
        hasSelectedGiftCard,
        giftCard,
        selectGiftCardAmount,
        setGiftCard,
    };
};
